import React, { useState } from 'react'
import PageLayout from '../../components/layouts/pageLayout'
import { VscBook } from "react-icons/vsc";
import { BiBookReader } from "react-icons/bi";
import { LuPodcast } from "react-icons/lu";
import StoriesTab from './storiesTab/storiesTab';
import BooksTab from './booksTab/booksTab';
import PodcastTab from './podcastTab/podcastTab';


const MyPublications = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = [
        {
            name: "Stories",
            icon: <BiBookReader className='h-5 w-5' />
        },
        {
            name: "Books",
            icon: <VscBook className='h-5 w-5' />
        },
        {
            name: "Podcasts",
            icon: <LuPodcast className='h-5 w-5' />
        }
    ]

    //tabComponents
    const tabComponents = [
        <StoriesTab />, <BooksTab />, <PodcastTab />
    ]


    return (
        <>
            <PageLayout pubsActive="active">
                <div className='w-full px-5 pt-5 pb-20 h-full text-black text-opacity-[80%] overflow-y-auto'>
                    <p className='text-[22px] font-[600]'>My Publications</p>

                    <div className="mt-10 w-full flex gap-10 items-center border-b border-gray-300 pb-2">
                        {
                            tabNames.map((tab, i) => (
                                <div key={i} className={`${activeTab === i ? 'bg-defaultPurple text-white border-defaultPurple' : ''} flex gap-3 items-center w-fit px-5 h-[40px] rounded border cursor-pointer`} onClick={() => setActiveTab(i)}>
                                    <div>{tab?.icon}</div>
                                    <p className='text-[14px] font-[500]'>{tab?.name}</p>
                                </div>
                            ))
                        }
                    </div>

                    {/* tab components */}
                    <div className="mt-5">
                        {
                            tabComponents[activeTab]
                        }
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default MyPublications
