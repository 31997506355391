import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { BaseServices } from '../../helpers/baseServices'


interface selectProps {
    onChange?: (data: any) => void
    defualtValue?: any
}


const LanguageSelector = ({ onChange, defualtValue }: selectProps) => {

    const [isBusy, setIsBusy] = useState(true)
    const [allLanguages, setAllLanguages] = useState([])


    //onload
    useEffect(() => {
        const getLanguages = async () => {
            setIsBusy(true)

            try {
                const response = await BaseServices.get(`/v1/language/list/`)
                console.log("languages", response)
                if (response?.data) {
                    const mapped = response?.data?.map((item: any) => (
                        { label: item?.name, value: item?.id }
                    ))
                    setAllLanguages(mapped)
                    setIsBusy(false)
                }
            } catch (error) {
                console.log(error)
            }
        }

        getLanguages()
    }, [])


    return (
        <>
            <Select id="language" className='h-full w-full' variant='borderless'
                options={allLanguages}
                onChange={onChange}
                loading={isBusy}
                defaultValue={defualtValue}
            />
        </>
    )
}

export default LanguageSelector
