
import { Navigate } from 'react-router-dom';
import { StorageBox } from '../helpers/stroage';

const ProtectedRoute = ({ children }: any) => {

  const token = StorageBox.getAccessToken();

  if (token) {

    return children;

  }
  else {
    return <Navigate to="/" />
  }

}

export default ProtectedRoute;