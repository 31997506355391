import React, { useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { MdOutlineDashboard, MdOutlineAnalytics, MdOutlineAudioFile } from "react-icons/md";
import { useGeneralContext } from "../../context/generalContext";
import { GiTakeMyMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { WEBSITE_URL } from "../../helpers/constants";


interface sideBarProps {
    dashActive?: any
    earnActive?: any
    analyticsActive?: any
    pubsActive?: any
}

const CustomSideBar = ({ dashActive, earnActive, analyticsActive, pubsActive }: sideBarProps) => {

    const { isCollapsed, setIsCollapsed, user_data } = useGeneralContext()


    // Function to handle window resize
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    };

    // Add event listener for window resize
    useEffect(() => {
        handleResize(); // Set initial state based on current window size
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };

        // eslint-disable-next-line 
    }, []);


    return (
        <Sidebar collapsed={isCollapsed}>
            <Menu className="h-screen bg-white border-r borer-r-300">
                {/* profile */}
                {
                    !isCollapsed &&
                    <div className="py-10">
                        <div className="flex justify-center">
                            <div className="h-[120px] w-[120px] rounded-full flex justify-center items-center bg-defaultGreen text-white">
                                <img className="h-full w-full object-cover rounded-full" src={user_data?.profile?.profile_photo || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt="" />
                            </div>
                        </div>

                        <div className="mt-3">
                            <p className="text-[14px] text-gray-500 text-center">{user_data?.profile?.name}</p>
                        </div>
                    </div>
                }

                <div className={`${isCollapsed ? 'mt-5' : ''}`}>
                    <MenuItem
                        icon={<MdOutlineDashboard className="w-6 h-6" />}
                        component={
                            <Link to="/dashboard" />
                        }
                        className={dashActive}
                    >
                        <p className="text-sm">Dashboard</p>
                    </MenuItem>
                </div>

                <div className="mt-3">
                    <MenuItem
                        icon={<GiTakeMyMoney className="w-6 h-6" />}
                        component={
                            <Link to="/all-transactions" />
                        }
                        className={earnActive}
                    >
                        <p className="text-sm">Earnings</p>
                    </MenuItem>
                </div>

                <div className="mt-3">
                    <MenuItem
                        icon={<MdOutlineAnalytics className="w-6 h-6" />}
                        component={
                            <Link to="/analytics" />
                        }
                        className={analyticsActive}
                    >
                        <p className="text-sm">Analytics</p>
                    </MenuItem>
                </div>

                <div className="mt-3">
                    <MenuItem
                        icon={<MdOutlineAudioFile className="w-6 h-6" />}
                        component={
                            <Link to="/my-publications" />
                        }
                        className={pubsActive}
                    >
                        <p className="text-sm">My Publications</p>
                    </MenuItem>
                </div>

                <div className="mt-3">
                    <MenuItem
                        icon={<AiOutlineHome className="w-6 h-6" />}
                        component={
                            <Link to={WEBSITE_URL} />
                        }
                        className=''
                    >
                        <p className="text-sm">Back to Buzsquare</p>
                    </MenuItem>
                </div>

            </Menu>
        </Sidebar>
    );
};

export default CustomSideBar;
