import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Lottie from "lottie-react";
import verifyJson from "../assets/verify.json"
import { StorageBox } from '../helpers/stroage';
import { message } from 'antd';


const Authorization = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    //onload
    useEffect(() => {
        if (id) {
            const user_info = StorageBox.retrieveUserData()
            console.log(user_info?.user_type)
            if (user_info?.user_type === "Professional") {
                setTimeout(() => {
                    navigate("/dashboard")
                }, 2000)
            } else if (user_info?.user_type === "Standard") {
                setTimeout(() => {
                    navigate("/onboarding")
                }, 2000)
            } else {
                message.error("You are not authorized to access this platform")
                setTimeout(() => {
                    navigate("/")
                    StorageBox.clearStorage()
                }, 2000)
            }
        }

        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <div className="h-screen w-full overflow-hidden">
                <div className="flex justify-center items-center h-full">
                    <div>
                        <Lottie animationData={verifyJson} loop={true} />
                        <p>Please wait while we verify your account...</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Authorization
