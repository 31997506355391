import React, { useState } from 'react'
import { HiBars3BottomLeft } from "react-icons/hi2";
import logoImg from "../../assets/Buzu.png"
import { AiOutlineAudio } from "react-icons/ai";
import UserProfile from '../shared/userProfile';
import { useGeneralContext } from '../../context/generalContext';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { VscBook } from "react-icons/vsc";
import { BiBookReader } from "react-icons/bi";
import { LuPodcast } from "react-icons/lu";
import PublishStoryModal from '../shared/publish/publishStoryModal';
import PublishPodcastModal from '../shared/publish/publishPodcastModal';


const CustomNavbar = () => {

    const { isCollapsed, setIsCollapsed } = useGeneralContext()
    //story modal
    const [openStoryModal, setOpenStoryModal] = useState(false)
    const handleOpenStory = () => {
        setOpenStoryModal(true)
    }
    const handleCloseStory = () => {
        setOpenStoryModal(false)
    }

    //podcast modal
    const [openPodcastModal, setOpenPodcastModal] = useState(false)
    const handleOpenPodcast = () => {
        setOpenPodcastModal(true)
    }
    const handleClosePodcast = () => {
        setOpenPodcastModal(false);
    }

    //publish dropdown menu
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenStory}>
                <BiBookReader className='h-5 w-5' />
                <p>Story</p>
            </button>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenStory}>
                <VscBook className='h-5 w-5' />
                <p>Book</p>
            </button>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenPodcast}>
                <LuPodcast className='h-5 w-5' />
                <p>Podcast</p>
            </button>,
            key: '2',
        },
    ];


    return (
        <>
            <div className='w-full py-3 2xl:px-10 xl:px-10 lg:px-10 md:px-5 px-5 bg-white shadow-sm border-b brder-b-gray-400 flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <button className='h-10 w-10 flex justify-center items-center' onClick={() => setIsCollapsed(!isCollapsed)}>
                        <HiBars3BottomLeft className='h-8 w-8' />
                    </button>

                    <div>
                        <img className='w-[200px]' src={logoImg} alt="" />
                    </div>
                </div>

                <div className='flex gap-5 items-center'>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <button className='px-5 h-[40px] flex gap-2 items-center rounded-[30px] bg-defaultPurple text-white'>
                            <AiOutlineAudio className='h-5 w-5' />
                            <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[12px]'>PUBLISH</p>
                        </button>
                    </Dropdown>

                    <div>
                        <UserProfile />
                    </div>
                </div>
            </div>

            {/* publish story modal */}
            {
                openStoryModal && <PublishStoryModal isOpened={openStoryModal} handleClose={handleCloseStory} />
            }

            {/* publish podcast modal */}
            {
                openPodcastModal && <PublishPodcastModal isOpened={openPodcastModal} handleClose={handleClosePodcast} />
            }
        </>
    )
}

export default CustomNavbar
