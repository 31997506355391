import React from 'react'
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;


const DateRangeComponent = () => {
    return (
        <>
            <div className="border border-gray-300 rounded p-2">
                <RangePicker className='h-[40px]' variant='borderless' />
            </div>
        </>
    )
}

export default DateRangeComponent
