import React, { useEffect, useState } from 'react'
import type { MenuProps } from 'antd';
import { Dropdown, Empty, message, Skeleton, Space, Spin } from 'antd';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { RxReload } from 'react-icons/rx';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { LoadingOutlined } from '@ant-design/icons';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import EditExistingStory from '../../../components/shared/publish/editExistingStory';
import { useGeneralContext } from '../../../context/generalContext';


const PublishedStories = () => {

    const { reFetch } = useGeneralContext()
    const templateData = Array.from({ length: 6 })
    const [isFetching, setIsFetching] = useState(false)
    const [allPublished, setAllPublished] = useState([])
    const [limit, setLimit] = useState(12)
    const [total, setTotal] = useState(0)
    const [selectedBook, setSelectedBook] = useState<any>(null)
    const [isBusy, setIsBusy] = useState(false)
    const [reFetchh, setReFetchh] = useState(false)

    //story or book edit modal
    const [openFinishStory, setOpenFinishStory] = useState(false)
    const handleOpenFinishStory = () => {
        setOpenFinishStory(true)
    }
    const handleCloseFinishStory = () => {
        setOpenFinishStory(false)
    }

    //getAllPublished
    const getAllPublished = async (lim: number) => {
        setIsFetching(true)
        try {
            const response = await BaseServices.get(`${global_variables.list_audio_book}?limit=${lim}&type=Story&status=Published`)
            // console.log("-----publishedStory:", response)
            setAllPublished(response?.data?.results)
            setTotal(response?.data?.count)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }


    //onload
    useEffect(() => {
        getAllPublished(limit)
    }, [limit, reFetchh, reFetch])


    const handleLoadMore = () => {
        if (limit !== total) {
            setLimit(limit + 4)
        }
    }


    //handleDeleteBook
    const handleDeleteBook = async () => {

        setIsBusy(true)
        try {
            const response = await BaseServices.delete(`/v1/library/audio-book/${selectedBook?.id}/delete/`)
            console.log("-----delBookResponse:", response)
            setIsBusy(false)
            message.success(`${selectedBook?.title || "Book"} has been deleted successfully!`)
            getAllPublished(limit)
        } catch (error: any) {
            console.log("------delBookError:", error)
            setIsBusy(false)
            message.error(error?.details || error?.response?.data?.error || error?.response?.data?.detail || "An error occured please retry!")
        }
    }



    //dropdown
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenFinishStory}>
                <BiPencil className='h-5 w-5' />
                <p>Edit</p>
            </button>,
            key: '1',
        },
        {
            label: <button className='flex gap-2 items-center text-red-500' onClick={handleDeleteBook}>
                <BiTrash className='h-5 w-5' />
                <p>Delete</p>
            </button>,
            key: '3',
        },
    ];


    return (
        <>
            {
                isFetching ?
                    <>
                        <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full gap-10">
                            {
                                templateData.map((item, i) => (
                                    <div key={i} className="py-[25px] border-t-[1px] border-t-[#D7DBE1]">
                                        <div className="flex w-full lg:gap-[25px] gap-[15px] items-center">
                                            <div className='mb-5 lg:mb-0'>
                                                <Skeleton.Image style={{ width: 200, height: 150, borderRadius: 17 }} active />
                                            </div>

                                            <div className=' w-full'>
                                                <Skeleton active />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                    :
                    allPublished.length === 0 ?
                        <>
                            <div className='mt-10'>
                                <Empty description={`You have no published Books`} />
                            </div>
                        </>
                        :
                        <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full gap-10">
                            {
                                allPublished.map((item: any, i: number) => (
                                    <div key={i} className="pr-5 border-[1px] border-[#D7DBE1] bg-white shadow-md rounded-[20px]">
                                        <div className="flex w-full items-center lg:gap-[25px] gap-[15px]">
                                            <div className='mb-5 lg:mb-0'>
                                                <img className='lg:w-[250px] w-[150px] h-[150px] rounded-[17px] object-cover' src={item?.cover_image || "https://imageresizer.furnituredealer.net/img/remote/images.furnituredealer.net/img/commonimages%2Fitem-placeholder.jpg"} alt="" />
                                            </div>

                                            <div className='flex justify-between lg:w-full'>
                                                <div className='text-black text-opacity-[80%]'>
                                                    <p className='text-[14px] font-[700]'>Title: <span className='font-[400]'>{item?.title}</span></p>

                                                    <p className='text-[14px] font-[700] mt-[3px]'>Author: <span className='font-[400]'>{item?.author || item?.hosts}</span></p>

                                                    <p className='text-[14px] font-[700] mt-[3px]'>Price: <span className='font-[400]'>GHS {item?.price}</span></p>

                                                    <p className='text-[14px] font-[700] mt-[3px]'>Subscribers: <span className='font-[400]'>{item?.total_subscribers}</span></p>

                                                    <p className='text-[14px] font-[700] mt-[3px]'>Total Revenue: <span className='font-[400]'>{item?.total_revenue}</span></p>
                                                </div>

                                                <div>
                                                    {
                                                        item?.id === selectedBook?.id && isBusy ?
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#000" }} spin />} />
                                                            :
                                                            <Dropdown className='hover:bg-gray-200 hover:rounded-full disabled:cursor-not-allowed cursor-pointer' menu={{ items }} trigger={['click']} placement='bottomCenter' disabled={isBusy}>
                                                                <button onClick={() => setSelectedBook(item)}>
                                                                    <Space>
                                                                        <HiOutlineDotsVertical className='h-6 w-6 text-black text-opacity-[80%]' />
                                                                    </Space>
                                                                </button>
                                                            </Dropdown>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
            }

            {/* load more */}
            <div className='mt-20'>
                {
                    isFetching ? <></>
                        :
                        allPublished.length === 0 ?
                            <></>
                            :
                            <div className="mt-[32px] flex items-center justify-between">
                                <div className='h-[1px] lg:w-[30%] w-[20%] bg-[#D7DBE1]'></div>

                                <button className='px-[15px] py-[5px] flex gap-[10px] items-center text-black text-opacity-[80%] border border-[#D7DBE1] rounded-[10px] disabled:cursor-not-allowed' onClick={handleLoadMore} disabled={total < limit}>
                                    <p className='text-[14px] font-[400]'>Load more</p>
                                    <RxReload className='h-[17px] w-[17px]' />
                                </button>

                                <div className='h-[1px] lg:w-[30%] w-[20%] bg-[#D7DBE1]'></div>
                            </div>
                }
            </div>



            {/* finish edit story or book modal */}
            {
                openFinishStory && <EditExistingStory isOpened={openFinishStory} handleClose={handleCloseFinishStory} tempBookData={selectedBook} handleReFetch={() => setReFetchh(!reFetchh)} />
            }

        </>
    )
}

export default PublishedStories
