import axios from "axios";

export const DoSignedAudioUpload = async (
  upload_url: any,
  audio_file: any,
  content_type: any
) => {
  const config = {
    method: "put",
    maxBodyLength: Infinity,
    url: upload_url,
    headers: {
      "Content-Type": content_type,
    },
    data: audio_file,
  };

  console.log(config);

  try {
    const response = await axios.request(config);
    return response;
  } catch (error) {
    return error;
  }
};
