import React, { useEffect, useState } from 'react'
import { BaseServices } from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { Empty, Skeleton, Tag } from 'antd';
import moment from 'moment';
import DataTable from 'react-data-table-component';


const RecentTransactionsTable = () => {


    const [isFetching, setIsFetching] = useState(true)
    const [allSubscription, setAllSubscription] = useState([])


    //onload
    useEffect(() => {
        const getAllSubscriptions = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.get_all_subscriptions}?offset=0&limit=10`)
                console.log("allSubscriptions:", response?.data)
                setAllSubscription(response?.data?.results)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllSubscriptions()
    }, [])


    //table columns
    const columns: any = [
        {
            name: <p className='text-[14px] font-[500]'>Date & Time</p>,
            cell: (row: any) => moment(row?.created_at).format("Do MMM YYYY - h:mm A"),
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Amount Paid (GHS)</p>,
            selector: (row: any) => row?.amount_paid,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Type</p>,
            selector: (row: any) => row?.type,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Title</p>,
            cell: (row: any) => row?.book?.title || row?.story?.title || row?.podcast?.title,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Subscriber</p>,
            selector: (row: any) => row?.user?.profile?.name,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Status</p>,
            selector: (row: any) => row?.status === "Pending" ? <Tag color='warning'>{row?.status}</Tag> : row?.status === "Completed" ? <Tag color='success'>{row?.status}</Tag> : <Tag color='error'>{row?.status}</Tag>,
            sortable: true,
        },
    ];


    return (
        <>
            <DataTable
                columns={columns}
                data={allSubscription}
                progressPending={isFetching}
                progressComponent={
                    <div className='w-full flex gap-5 items-center'>
                        <Skeleton active paragraph />
                        <Skeleton active paragraph />
                    </div>
                }
                noDataComponent={<Empty description="There are no records to display" />}
                pagination
                highlightOnHover
                pointerOnHover
            />
        </>
    )
}

export default RecentTransactionsTable
