import React from 'react'
import { Empty } from 'antd';
import DataTable from "react-data-table-component";


const WalletActivitiesTable = () => {

    //table columns
    const columns = [
        {
            name: "ID",
            selector: (row: any) => "",
        },
        {
            name: "Full Name",
            selector: (row: any) => "",
        },
        {
            name: "Phone Number",
            selector: (row: any) => "",
        },
        {
            name: "Amount (GHS)",
            selector: (row: any) => "",
        },
        {
            name: "Status",
            selector: (row: any) => ""
        },
        {
            name: "Date & Time",
            selector: (row: any) => "",
        },
    ];

    return (
        <>
            <DataTable
                className="mt-3"
                columns={columns}
                data={[]}
                pagination
                paginationServer
                noDataComponent={<Empty description="There are no records to display" />}
            />
        </>
    )
}

export default WalletActivitiesTable
