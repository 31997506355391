import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { BiBookReader } from 'react-icons/bi'
import { BaseServices } from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { useGeneralContext } from '../../../context/generalContext'

const PublishedStories = () => {

    const { reFetch } = useGeneralContext()
    const [isFetching, setIsFetching] = useState(true)
    const [pubStories, setPubStories] = useState<any>(0)

    //onload
    useEffect(() => {
        const getPubStories = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.list_audio_book}?limit=1&type=Story&status=Published`)
                // console.log("pubStories:", response?.data)
                setPubStories(response?.data?.count)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getPubStories()
    }, [reFetch])


    return (
        <>
            {
                isFetching ?
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm">
                        <div>
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>
                    </div>
                    :
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm flex justify-between items-center">
                        <div>
                            <p className='text-[18px] font-[500]'>Published Stories</p>

                            <p className='text-[24px] font-[600] mt-3'>
                                {pubStories}
                            </p>
                        </div>

                        <div>
                            <BiBookReader className='h-10 w-10' />
                        </div>
                    </div>
            }
        </>
    )
}

export default PublishedStories
