import { Input, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch?: () => void
}

const WithdrawalModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [selectedMeth, setSelectedMeth] = useState<any>("")

    return (
        <>
            <Modal open={isOpened} onCancel={handleClose} keyboard={false} maskClosable={false} footer={null} closeIcon={false} centered>
                <div>
                    <div className="flex justify-between items-center">
                        <p className='text-[18px] font-[500]'>Request Payout</p>
                        <IoCloseOutline className='h-6 w-6 cursor-pointer' onClick={handleClose} />
                    </div>

                    <hr className='my-3' />

                    <div className='w-full'>
                        <label htmlFor="meth">Choose Payout Method</label>
                        <Select
                            className='h-[40px] w-full'
                            placeholder="Select"
                            options={[
                                { label: "Bank Transfer", value: "Bank Transfer" },
                                { label: "Mobile Money", value: "Mobile Money" },
                            ]}
                            id='meth'
                            onChange={(selected) => setSelectedMeth(selected)}
                        />
                    </div>

                    {
                        selectedMeth === "Bank Transfer" ?
                            <>
                                <div className='w-full mt-4'>
                                    <label htmlFor="bname">Enter Bank Name</label>
                                    <Input className='h-[40px] w-full' id='bname' placeholder='Eg: Ecobank' />
                                </div>

                                <div className='w-full mt-4'>
                                    <label htmlFor="acc">Enter Bank Acc. No.</label>
                                    <Input className='h-[40px] w-full' id='acc' placeholder='Eg: 27755005000005' />
                                </div>

                                <div className='w-full mt-4'>
                                    <label htmlFor="branch">Enter Bank Branch</label>
                                    <Input className='h-[40px] w-full' id='branch' placeholder='Eg: Accra' />
                                </div>
                            </>
                            :
                            selectedMeth === "Mobile Money" ?
                                <>
                                    <div className='w-full mt-4'>
                                        <label htmlFor="net">Choose Network</label>
                                        <Select
                                            className='h-[40px] w-full'
                                            placeholder="Select"
                                            options={[
                                                { label: "MTN Momo", value: "mtn" },
                                                { label: "Telecel Cash", value: "telecel" },
                                                { label: "AT Money", value: "at" }
                                            ]}
                                            id='net'
                                        />
                                    </div>

                                    <div className='w-full mt-4'>
                                        <label htmlFor="momo">Enter Mobile Money No.</label>
                                        <Input className='h-[40px] w-full' id='momo' placeholder='Eg: 0550000000' />
                                    </div>
                                </>
                                :
                                <></>
                    }

                    <div className='w-full mt-4'>
                        <label htmlFor="amt">Enter Amount</label>
                        <Input className='h-[40px] w-full' type='number' id='amt' placeholder='Eg: 500' />
                    </div>

                    <div className='w-full mt-4'>
                        <label htmlFor="name">Enter Full Name</label>
                        <Input className='h-[40px] w-full' id='name' placeholder='Eg: John Doe' />
                    </div>

                    {/* button */}
                    <div className="w-full mt-5">
                        <button className='h-[50px] w-full bg-gradient-to-r from-purple-400 to-defaultPurple text-white rounded'>
                            Request Payout
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default WithdrawalModal
