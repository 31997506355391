// src/contexts/AppContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { StorageBox } from '../helpers/stroage';
import { BaseServices } from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { sumArray } from '../functions/sumArray';


// Create the context with a default value
const GeneralContext = createContext<any>(null);

// Create a provider component
export const GeneralContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const user_data = StorageBox.retrieveUserData()
    const [isCollapsed, setIsCollapsed] = useState<string>('');
    const [totalListens, setTotalListens] = useState<any>(0)
    const [reFetch, setReFetch] = useState(false)

    //onload
    useEffect(() => {

        const getTotalListens = async () => {
            try {
                const response = await BaseServices.get(`${global_variables.get_total_listens}?time_period=yearly`)
                const totList = response?.data?.total_listens || []
                setTotalListens(sumArray(totList))
            } catch (error) {
                console.log(error)
            }
        }

        getTotalListens()
    }, [])

    return (
        <GeneralContext.Provider value={{ isCollapsed, setIsCollapsed, user_data, totalListens, reFetch, setReFetch }}>
            {children}
        </GeneralContext.Provider>
    );
};

// Create a custom hook for easy access to the context
export const useGeneralContext = () => {
    const context = useContext(GeneralContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
