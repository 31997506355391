import React, { useEffect, useState } from 'react'
import { GiTakeMyMoney } from 'react-icons/gi'
import { BaseServices } from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { Skeleton } from 'antd'
import { useGeneralContext } from '../../../context/generalContext'

const TotalEarnings = () => {

    const { reFetch } = useGeneralContext()
    const [isFetching, setIsFetching] = useState(true)
    const [allEarnings, setAllEarnings] = useState<any>(0)

    //onload
    useEffect(() => {
        const getAllEarnings = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.get_total_earnings}`)
                // console.log("allEarnings:", response?.data)
                setAllEarnings(response?.data?.total_earnings)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllEarnings()
    }, [reFetch])

    return (
        <>
            {
                isFetching ?
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm">
                        <div>
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>
                    </div>
                    :
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm flex justify-between items-center">
                        <div>
                            <p className='text-[18px] font-[500]'>All Time Earnings</p>

                            <p className='text-[24px] font-[600] mt-3'>
                                GHS {allEarnings || "0.00"}
                            </p>
                        </div>

                        <div>
                            <GiTakeMyMoney className='h-10 w-10' />
                        </div>
                    </div>
            }
        </>
    )
}

export default TotalEarnings
