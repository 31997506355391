import React from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ConfettiComponent: React.FC<{ run: boolean }> = ({ run }) => {
    const { width, height } = useWindowSize();

    return <>{run && <Confetti width={width} height={height} />}</>;
};

export default ConfettiComponent;
