"use client";

import React, { useState } from 'react'
import { Input, Modal, Spin, message } from 'antd'
import { BiTrash } from 'react-icons/bi'
import { LoadingOutlined } from '@ant-design/icons';
import { GetSignedUrl } from '../../../functions/getSignedAudioUrl';
import { DoSignedAudioUpload } from '../../../functions/doSignedAudioUpload';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import AudioSampleUploader from '../../mediaUploader/audioSampleUploader';
import SampleAudioComponent from '../sampleAudioComponent';



interface modalProps {
    isOpened: boolean
    handleCloseAddEpisode: () => void
    chapterInfo: any
    handleReFetch: () => void
}


const AddStoryEpisodes = ({ isOpened, handleCloseAddEpisode, chapterInfo, handleReFetch }: modalProps) => {

    const [isCreatingEpisode, setIsCreatingEpisode] = useState(false)
    const [episodeNumber, setEpisodeNumber] = useState<any>(null)
    const [episodeTitle, setEpisodeTitle] = useState<any>(null)
    const [episodeAudioFile, setEpisodeAudioFile] = useState<any>(null)
    const [episodeAudioUrl, setEpisodeAudioUrl] = useState<any>(null)
    const [audioFileName, setAudioFileName] = useState<any>("")


    //handleEpisodeFileChange
    const handleEpisodeFileChange = (event: any) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            console.log("audioExtension:", file)
            setAudioFileName(file?.name)
            setEpisodeAudioFile(file);
            const url = URL.createObjectURL(file);
            setEpisodeAudioUrl(url);
        }
    };

    //handleCreateEpisode
    const handleCreateEpisode = async () => {
        if (!episodeNumber) {
            message.warning("Episode number cannot be empty")
        } else if (!episodeTitle) {
            message.warning("Add episode title")
        } else if (!episodeAudioUrl) {
            message.warning("Add eppisode audio")
        }
        else {
            let data = new FormData();
            data.append('title', episodeTitle);
            data.append('number', episodeNumber);
            data.append('description', '');

            setIsCreatingEpisode(true)

            //get audio upload url
            GetSignedUrl(audioFileName, "audiobook_episode")
                .then((signedRes) => {
                    console.log("signedRes:", signedRes)
                    //signed upload
                    DoSignedAudioUpload(signedRes?.upload_url, episodeAudioFile, signedRes?.content_type)
                        .then((uploadRes: any) => {
                            console.log("uploadRes:", uploadRes)
                            if (uploadRes?.status === 200) {
                                data.append('audio', signedRes?.media_url);

                                //create actual episode
                                BaseServices.post(`${global_variables.audio_book_url}/chapter/${chapterInfo?.id}/episode/create/`, data)
                                    .then((response) => {
                                        console.log(response)
                                        message.success(`New episode added to Chapter ${chapterInfo?.number}`)
                                        setEpisodeTitle(null)
                                        setEpisodeNumber(null)
                                        setEpisodeAudioFile(null)
                                        setEpisodeAudioUrl(null)
                                        handleReFetch()
                                        handleCloseAddEpisode()
                                    }).catch((error) => {
                                        console.log(error)
                                        message.error(error?.response?.data?.detail || error?.response?.data?.error || "An error occured. Try again!")
                                        setIsCreatingEpisode(false)
                                    })
                            } else {
                                setIsCreatingEpisode(false)
                                message.error("Audio upload failed, please restart the process.")
                            }
                        })
                        .catch((uploadErr) => {
                            //signed upload err
                            setIsCreatingEpisode(false)
                            console.log("uploadErr:", uploadErr)
                            message.error(uploadErr?.details || uploadErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                        })
                }).catch((signedErr) => {
                    //get audio upload url error
                    setIsCreatingEpisode(false)
                    console.log("signedErr:", signedErr)
                    message.error(signedErr?.details || signedErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                })
        }
    }

    return (
        <>
            <Modal open={isOpened} onCancel={handleCloseAddEpisode} footer={null} centered keyboard={false} maskClosable={false}>
                <p className='text-[26px] font-[700]'>Add episode (Chapter {chapterInfo?.number})</p>

                <>
                    <div className='mt-5'>
                        <label htmlFor='epnumber' className='text-[16px] font-[400]'>Episode number</label>
                        <Input id="epnumber" type='number' min={1} className={`w-full h-[52px] rounded-[20px] mt-[7px]`} value={episodeNumber} onChange={(e) => setEpisodeNumber(e.target.value)} disabled={isCreatingEpisode} />
                    </div>

                    <div className='mt-5'>
                        <label htmlFor='eptitle' className='text-[16px] font-[400]'>Episode title</label>
                        <Input id="eptitle" className={`w-full h-[52px] rounded-[20px] mt-[7px]`} value={episodeTitle} onChange={(e) => setEpisodeTitle(e.target.value)} disabled={isCreatingEpisode} />
                    </div>

                    <div className="mt-5">
                        <small className='text-[16px] font-[400]'>Upload Audio</small>
                        <AudioSampleUploader id='episode' onChange={handleEpisodeFileChange} placeholder='Select episode to upload' />
                        {episodeAudioUrl &&
                            <div className='mt-2 flex items-center gap-2'>
                                <SampleAudioComponent sampleAudio={episodeAudioUrl} />
                                <BiTrash className='h-5 w-5 text-red-500 cursor-pointer' onClick={() => {
                                    setEpisodeAudioFile(null)
                                    setEpisodeAudioUrl(null)
                                }} />
                            </div>
                        }
                    </div>
                </>

                <div className="mt-[30px]">
                    <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' type='submit' onClick={handleCreateEpisode} disabled={isCreatingEpisode}>
                        {
                            isCreatingEpisode ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                :
                                <p className='text-[16px] font-[700]'>Save</p>
                        }
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default AddStoryEpisodes
