import React from 'react'
import { MdOutlineFileUpload } from "react-icons/md";

interface theProps {
    placeholder?: string
    onChange: any
}

const BannerImageUploader = ({ placeholder, onChange }: theProps) => {

    return (
        <>
            <label htmlFor='banner' className='text-[16px] font-[400]'>
                <div className='w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%] text-black text-opacity-[36%] cursor-pointer flex items-center gap-[9px] justify-center'>
                    <MdOutlineFileUpload className='w-5 h-5' />
                    <p className='text-[16px] font-[400]'>{placeholder || 'Select'}</p>
                </div>
            </label>
            <input id="banner" type='file' className='w-full h-[52px] rounded-[20px] mt-[7px] hidden' onChange={onChange} />
        </>
    )
}

export default BannerImageUploader
