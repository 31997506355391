import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/layouts/pageLayout'
import WalletActivitiesTable from './walletActivities'
import WithdrawalModal from './withdrawalModal'
import { BaseServices } from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import { StorageBox } from '../../helpers/stroage'
import { Skeleton } from 'antd'


const MyWallet = () => {

    const user_data = StorageBox.retrieveUserData()
    const [isFetching, setIsFetching] = useState(true)
    const [allEarnings, setAllEarnings] = useState<any>(0)

    //withdraw modal state
    const [openWithdraw, setOpenWithdraw] = useState(false)


    //onload
    useEffect(() => {
        const getAllEarnings = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.get_total_earnings}`)
                console.log("allEarnings:", response?.data)
                setAllEarnings(response?.data?.total_earnings)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getAllEarnings()
    }, [])


    return (
        <>
            <PageLayout>
                <div className='w-full px-5 pt-5 pb-20 h-full text-black text-opacity-[80%] overflow-y-auto'>
                    <p className='text-[22px] font-[600]'>My Wallet</p>

                    <div className="mt-10 w-full">
                        {/* wallet card */}
                        <div className="w-full lg:w-[500px] h-fit p-5 rounded shadow-md bg-gradient-to-r from-defaultPurple via-purple-300 to-purple-400 text-white">
                            {
                                isFetching ?
                                    <Skeleton active paragraph={{ rows: 3 }} />
                                    :
                                    <>
                                        <p className='text-sm'>Estimated Earnings</p>
                                        <p className='text-[28px] font-[700]'>GHS {allEarnings || "0.00"}</p>

                                        <div className="mt-5 flex justify-between items-center">
                                            <div>
                                                <p>{user_data?.profile?.name}</p>
                                            </div>

                                            <div>
                                                <button className='px-4 py-1 rounded border border-white shadow-sm' onClick={() => setOpenWithdraw(true)}>
                                                    <p>Withdraw</p>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>

                        {/* wallet activities */}
                        <div className="w-full mt-10 border border-gray-300 p-5 rounded shadow-sm">
                            <p className='text-[18px] font-[500]'>Wallet activities</p>

                            <div className="mt-5">
                                <WalletActivitiesTable />
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>

            {/* withdrawal modal */}
            {
                openWithdraw && <WithdrawalModal isOpened={openWithdraw} handleClose={() => setOpenWithdraw(false)} />
            }
        </>
    )
}

export default MyWallet
