import React from 'react'
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { IoWalletOutline, IoPowerOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useGeneralContext } from '../../context/generalContext';
import { StorageBox } from '../../helpers/stroage';


const UserProfile = () => {

    const navigate = useNavigate()
    const { user_data } = useGeneralContext()

    //dropdown menu
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={() => navigate("/my-wallet")}>
                <IoWalletOutline className='h-5 w-5' />
                <p>My Wallet</p>
            </button>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <button className='flex gap-2 items-center text-red-500' onClick={() => {
                StorageBox.clearStorage()
                navigate("/")
            }}>
                <IoPowerOutline className='h-5 w-5' />
                <p>Sign Out</p>
            </button>,
            key: '1',
        },
    ];


    return (
        <>
            <Dropdown menu={{ items }} trigger={['click']}>
                <div className='h-10 w-10 rounded-full bg-defaultGreen text-white flex justify-center items-center cursor-pointer'>
                    <img className="h-full w-full object-cover rounded-full" src={user_data?.profile?.profile_photo || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt="" />
                </div>
            </Dropdown>

        </>
    )
}

export default UserProfile
