import React, { useState } from 'react'
import PublishedPodcasts from './publishedPodcasts'
import DraftedPodcasts from './draftedPodcasts'

const PodcastTab = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = [
        "Published", "Draft"
    ]

    //tabComponents
    const tabComponents = [
        <PublishedPodcasts />, <DraftedPodcasts />
    ]

    return (
        <>
            <div className='w-full'>
                <div className="mt-5 w-full flex gap-10 items-center">
                    {
                        tabNames.map((tab, i) => (
                            <div key={i} className={`border-b-[4px] px-3 cursor-pointer ${activeTab === i ? 'border-b-defaultPurple' : 'border-b-transparent'}`} onClick={() => setActiveTab(i)}>
                                <p className='text-[16px] font-[500]'>{tab}</p>
                            </div>
                        ))
                    }
                </div>

                {/* tab components */}
                <div className="mt-5">
                    {
                        tabComponents[activeTab]
                    }
                </div>
            </div>
        </>
    )
}

export default PodcastTab
