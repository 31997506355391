import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/layouts/pageLayout'
import DateRangeComponent from '../../components/shared/dateRangeComponent';
import { BaseServices } from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { Empty, Skeleton, Tag } from 'antd';
import moment from 'moment';
import DataTable from 'react-data-table-component';


const AllTransactions = () => {

    const [isFetching, setIsFetching] = useState(true)
    const [allSubscription, setAllSubscription] = useState([])
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [total, setTotal] = useState(0)

    //getAllSubscriptions
    const getAllSubscriptions = async (off: number, lim: number) => {
        setIsFetching(true)

        try {
            const response = await BaseServices.get(`${global_variables.get_all_subscriptions}?offset=${off}&limit=${lim}`)
            console.log("allSubscriptions:", response?.data)
            setAllSubscription(response?.data?.results)
            setTotal(response?.data?.count)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //onload
    useEffect(() => {
        getAllSubscriptions(offset, limit)
    }, [offset, limit])

    // pagination start
    const handleRowChange = (a: any) => {
        setLimit(a);
    }

    const pageChange = (a: any) => {
        if (a === 1) {
            setOffset(0)
        } else {
            const newSkip = (a * limit) - limit;
            setOffset(newSkip);
        }
    }
    // pagination end


    //table columns
    const columns: any = [
        {
            name: <p className='text-[14px] font-[500]'>Date & Time</p>,
            cell: (row: any) => moment(row?.created_at).format("Do MMM YYYY - h:mm A"),
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Amount Paid (GHS)</p>,
            selector: (row: any) => row?.amount_paid,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Type</p>,
            selector: (row: any) => row?.type,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Title</p>,
            cell: (row: any) => row?.book?.title || row?.story?.title || row?.podcast?.title,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Subscriber</p>,
            selector: (row: any) => row?.user?.profile?.name,
            sortable: true,
        },
        {
            name: <p className='text-[14px] font-[500]'>Status</p>,
            selector: (row: any) => row?.status === "Pending" ? <Tag color='warning'>{row?.status}</Tag> : row?.status === "Completed" ? <Tag color='success'>{row?.status}</Tag> : <Tag color='error'>{row?.status}</Tag>,
            sortable: true,
        },
    ];


    return (
        <>
            <PageLayout earnActive="active">
                <div className='w-full px-5 pt-5 pb-20 h-full text-black text-opacity-[80%] overflow-y-auto'>
                    <div className="w-full flex justify-between items-center">
                        <p className='text-[22px] font-[600]'>Earnings</p>
                        <div className="">
                            <DateRangeComponent />
                        </div>
                    </div>

                    <div className="mt-10 w-full border border-gray-300 p-5 rounded shadow-sm">
                        <DataTable
                            columns={columns}
                            data={allSubscription}
                            progressPending={isFetching}
                            progressComponent={
                                <div className='w-full flex gap-5 items-center'>
                                    <Skeleton active paragraph={{ rows: 12 }} />
                                    <Skeleton active paragraph={{ rows: 12 }} />
                                </div>
                            }
                            noDataComponent={<Empty description="There are no records to display" />}
                            pagination
                            paginationServer
                            highlightOnHover
                            pointerOnHover
                            onChangeRowsPerPage={handleRowChange}
                            onChangePage={pageChange}
                            paginationTotalRows={total}
                        />
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default AllTransactions
