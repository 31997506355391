"use client";

import React, { useEffect, useState } from 'react'
import { Input, Modal, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';



interface modalProps {
    isOpened: boolean
    handleCloseEditChapter: () => void
    chapterInfo: any
    handleReFetch: () => void
}


const EditStoryChapter = ({ isOpened, handleCloseEditChapter, chapterInfo, handleReFetch }: modalProps) => {

    const [isCreatingChapter, setIsCreatingChapter] = useState(false)
    const [chapterTitle, setChapterTitle] = useState("")

    //onload
    useEffect(() => {
        if (chapterInfo) {
            console.log("-----chapterToEdit:", chapterInfo)
            setChapterTitle(chapterInfo?.title)
        }

        // eslint-disable-next-line
    }, [])

    //handleUpdateChapter
    const onSubmit = async () => {
        if (chapterTitle) {
            const chapter_data = {
                "title": chapterTitle,
                // "number": parseInt(values?.number)
            }

            setIsCreatingChapter(true)
            try {
                const response = await BaseServices.patch(`${global_variables?.retrieve_single_book}/${chapterInfo?.audio_book?.id}/chapter/${chapterInfo?.id}/update/`, chapter_data)
                console.log(response?.data)
                message.success("Chapter was updated successfully!")
                setIsCreatingChapter(false)
                handleReFetch()
                setChapterTitle("")
            } catch (error: any) {
                console.log(error)
                message.error(error?.response?.data?.detail || error?.response?.data?.error || "An error occured. Try again!")
                setIsCreatingChapter(false)
            }
        }
    }




    return (
        <>
            <Modal open={isOpened} onCancel={handleCloseEditChapter} footer={null} closeIcon={false} centered>
                <p className='text-[26px] font-[700]'>Update a chapter</p>

                <div className='mt-5'>
                    <label htmlFor='title' className='text-[16px] font-[400]'>Chapter title <small className='text-xs text-red-500'>required</small></label>
                    <Input id="title" className={`w-full h-[52px] rounded-[20px] mt-[7px]`} value={chapterTitle} onChange={(e) => setChapterTitle(e.target.value)} disabled={isCreatingChapter} />
                </div>

                <div className="mt-[30px]">
                    <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' type='submit' onClick={onSubmit} disabled={isCreatingChapter}>
                        {
                            isCreatingChapter ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                :
                                <p className='text-[16px] font-[700]'>Update</p>
                        }
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default EditStoryChapter
