import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/layouts/pageLayout'
import ListensChart from './charts/listensChart';
import SubscribersChart from './charts/subscribersChart';
import EarningsChart from './charts/earningsChart';
import PeriodRangeFilter from '../../components/shared/periodRangeFilter';
import { BaseServices } from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { sumArray } from '../../functions/sumArray';
import CustomSpinner from '../../components/shared/customSpinner';
import { useGeneralContext } from '../../context/generalContext';


const AnalyticsPage = () => {

    const { totalListens } = useGeneralContext()
    const [activeTab, setActiveTab] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<any>("daily")
    const [theResults, setTheResults] = useState<any>(null)
    const [listenResults, setListenResults] = useState<any>(null)
    const [yearSubs, setYearSubs] = useState<any>(null)
    const [mostPerforming, setMostPerforming] = useState<any>(null)

    //tabNames
    const tabNames = [
        { name: "Subscribers", count: sumArray(theResults?.total_subscribers) || 0 },
        { name: "Listens", count: sumArray(listenResults?.total_listens) || 0 },
        { name: "Earnings", count: "GHS " + sumArray(theResults?.total_earnings) || 0 }
    ]

    //tabComponents
    const tabComponents = [
        <SubscribersChart results={theResults} />,
        <ListensChart results={listenResults} />,
        <EarningsChart results={theResults} />
    ]


    //getAllSubscribers
    const getAllSubscribers = async () => {

        setIsLoading(true)
        try {
            const response = await BaseServices.get(`${global_variables.get_chart_data}?time_period=${selectedTimePeriod}`)
            const lsitenRes = await BaseServices.get(`${global_variables.get_total_listens}?time_period=${selectedTimePeriod}`)
            console.log("subs:", response?.data)
            console.log("listens:", lsitenRes?.data)
            setTheResults(response?.data)
            setListenResults(lsitenRes?.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }


    //getTotalSubscribers
    const getTotalSubscribers = async () => {
        try {
            const response = await BaseServices.get(`${global_variables.get_chart_data}?time_period=yearly`)
            console.log("subsYearly:", response?.data)
            setYearSubs(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    //getMostPerforming
    const getMostPerforming = async () => {

        try {
            const response = await BaseServices.get(`${global_variables.most_performing}?offset=0&limit=1`)
            console.log("performingStoryRes:", response?.data)
            setMostPerforming(response?.data?.count || 0)
        } catch (error) {
            console.log(error)
        }
    }

    //onload
    useEffect(() => {

        getAllSubscribers()

        // eslint-disable-next-line
    }, [selectedTimePeriod])

    useEffect(() => {
        getTotalSubscribers()
        getMostPerforming()
    }, [])

    return (
        <>
            <PageLayout analyticsActive="active">
                <div className='w-full px-5 pt-5 pb-20 h-full text-black text-opacity-[80%] overflow-y-auto'>
                    <div className="flex justify-between items-center">
                        <p className='text-[22px] font-[600]'>Analytics</p>

                        <div className='w-[200px]'>
                            <PeriodRangeFilter onChange={(value) => setSelectedTimePeriod(value)} />
                        </div>
                    </div>

                    {/* grid */}
                    <div className="mt-10 grid grid-cols-3 gap-10 w-full">
                        {/* left */}
                        <div className="w-full col-span-2 border border-gray-300 p-5 rounded shadow-sm">
                            <p className='text-[38px] font-[700] text-black text-opacity-[80%] text-center leading-[46px]'>
                                {
                                    totalListens <= 0 ? "Your publications didn't get any listens in the last 28 days"
                                        :
                                        `Your publications got ${totalListens} listens in the last 28 days`
                                }
                            </p>

                            {/* tabs */}
                            <div className="mt-10 flex w-full items-center">
                                {
                                    tabNames.map((tab, i) => (
                                        <div key={i} className={`${activeTab === i ? 'border-t-[4px] border-t-defaultPurple rounded-t-[5px] bg-gray-50' : 'border-x border-x-gray-300 border-t border-t-gray-300'} w-full p-5 text-center cursor-pointer`} onClick={() => setActiveTab(i)}>
                                            <p>{tab?.name}</p>
                                            {
                                                isLoading ? <CustomSpinner style={{ fontSize: 28, color: "#8B49DF", marginTop: 2 }} />
                                                    :
                                                    <p className='text-[28px] font-[600]'>{tab?.count}</p>
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                            {/* tabComponents */}
                            <div className="w-full">
                                {
                                    tabComponents[activeTab]
                                }
                            </div>
                        </div>

                        {/* right */}
                        <div className="w-full border border-gray-300 p-5 rounded shadow-s h-fit">
                            <p className='text-[22px] font-[600]'>Studio Analytics</p>

                            <div className='mt-3'>
                                <p className='text-[16px] font-[400]'>Current Subscribers</p>
                                <p className='text-[24px] font-[600]'>
                                    {sumArray(yearSubs?.total_subscribers)}
                                </p>
                            </div>

                            <hr className='my-5' />

                            <div className='mt-3'>
                                <p className='text-[16px] font-[500]'>Summary</p>
                                <p className='text-[16px] font-[400] text-gray-500'>All Time</p>

                                <div className='mt-3 flex justify-between items-center'>
                                    <p className='text-[16px] font-[500]'>Listens</p>
                                    <p className='text-[16px] font-[500]'>
                                        {totalListens}
                                    </p>
                                </div>
                            </div>

                            <hr className='my-5' />

                            <div className='mt-3'>
                                <div className='mt-3 flex justify-between items-center'>
                                    <p className='text-[16px] font-[500]'>Top Publications</p>
                                    <p className='text-[16px] font-[500]'>{mostPerforming}</p>
                                </div>
                                <p className='text-[16px] font-[400] text-gray-500'>All Time</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default AnalyticsPage
