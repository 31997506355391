import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "./constants";
const SECRET_KEY: any = CRYPTO_KEY;

export const StorageBox = {
  getAccessToken: () => {
    if (typeof window !== "undefined") {
      const encryptedData: any = localStorage.getItem("access_token");
      if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(
          encryptedData,
          SECRET_KEY
        ).toString(CryptoJS.enc.Utf8);
        return decryptedData;
      }
      return null;
    }
  },
  saveAccessToken: (token: any) => {
    if (token) {
      const encryptedData = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
      localStorage.setItem("access_token", encryptedData);
    }
  },
  saveUserData: (data: any) => {
    if (data) {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        SECRET_KEY
      ).toString();
      localStorage.setItem("userData", encryptedData);
    }
  },
  saveUserEmail: (data: any) => {
    if (data) {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        SECRET_KEY
      ).toString();
      localStorage.setItem("email", encryptedData);
    }
  },
  retrieveUserData: () => {
    if (typeof window !== "undefined") {
      const encryptedData = localStorage.getItem("userData");
      if (encryptedData !== null) {
        const decryptedData = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );
        // console.log(decryptedData);
        return decryptedData;
      } else {
        return null;
      }
    }
  },
  retrieveUserEmail: () => {
    if (typeof window !== "undefined") {
      const encryptedData = localStorage.getItem("email");
      if (encryptedData !== null) {
        const decryptedData = JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(
            CryptoJS.enc.Utf8
          )
        );
        // console.log(decryptedData);
        return decryptedData;
      } else {
        return null;
      }
    }
  },
  clearStorage: () => {
    localStorage.clear();
  },
};
