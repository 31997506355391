import React, { useState } from 'react';
import { Checkbox, Form, message, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import logo from "../../../assets/BUZS.png"
import { StorageBox } from '../../../helpers/stroage';
import ConfettiComponent from '../../../components/confetti/ConfettiComponent';


const OnboardingUser = () => {

    const [form] = useForm()
    const token = StorageBox.getAccessToken()
    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [runCongrats, setRunCongrats] = useState(false)

    //on btn clicked
    const onFormFinished = async (values: any) => {
        console.log('Form values:', values);
        if (values?.accepted === false) {
            message.warning("Please read and accept the terms and conditions")
        } else {
            setIsBusy(true)
            setRunCongrats(true)

            try {
                const response = await BaseServices.post(global_variables.create_prof_account)
                console.log("profRes:", response?.data)
                setTimeout(() => {
                    setIsBusy(false)
                    setRunCongrats(false)
                    if (token) {
                        navigate("/dashboard")
                    } else {
                        navigate("/")
                    }
                }, 4000)
            } catch (error: any) {
                console.log(error)
                message.error(error?.response?.data?.error || "An unknown error occured. Please retry")
                setIsBusy(false)
            }
        }
    }

    return (
        <>
            <div className="h-screen w-full overflow-hidden">
                <ConfettiComponent run={runCongrats} />

                <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 h-full">
                    {/* left */}
                    <div className="w-full h-full 2xl:flex xl:flex lg:flex md:flex justify-center items-center hidden bg-gradient-to-b from-purple-300 to-defaultPurple">
                        <div>
                            <img className='w-[350px]' src={logo} alt="" />
                            <p className='text-[32px] font-bold text-center text-white'>BUZSQUARE</p>
                            <p className='text-[16px] text-white mt-2 text-center'>Professional Account for Publishers and Content Owners</p>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full h-full overflow-y-auto 2xl:px-20 xl:px-20 lg:px-10 md:px-5 px-5">
                        {/* accept */}
                        <div className="w-full h-full flex justify-center items-center">
                            <Spin spinning={isBusy}>
                                <Form
                                    className='w-full'
                                    name="acceptTerms"
                                    form={form}
                                    onFinish={onFormFinished}
                                    layout="vertical"
                                    disabled={isBusy}
                                >
                                    <div className='w-full text-black text-opacity-[80%]'>
                                        <p className='text-[26px] font-[500]'>
                                            Please confirm that you have read our Terms and Conditions?
                                            <span className='text-[14px] ml-2 text-blue-500 hover:underline'>
                                                <Link to="#">Click here to read</Link>
                                            </span>
                                        </p>

                                        <div className="mt-5">
                                            <Form.Item
                                                name="accepted"
                                                valuePropName="checked"
                                                rules={[{ required: true, message: 'Please read and accept the terms and conditions' }]}
                                            >
                                                <Checkbox className='text-gray-500'>
                                                    I accept all the terms and conditions related to having a professional account on Buzsquare
                                                </Checkbox>
                                            </Form.Item>
                                        </div>

                                        <div className="w-full mt-10">
                                            <button
                                                type="submit"
                                                className='w-full h-[40px] rounded-[5px] text-defaultPurple border border-defaultPurple hover:bg-defaultPurple hover:text-white'
                                            >
                                                Setup My Account
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnboardingUser;
