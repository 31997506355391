import React, { useRef, useState, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import AudioPlayer from 'react-h5-audio-player';

interface SampleAudioComponentProps {
  sampleAudio?: string;
}

const SampleAudioComponent: React.FC<SampleAudioComponentProps> = ({ sampleAudio }) => {
  const audioPlayerRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handlePlayPause = () => {
    if (audioPlayerRef.current) {
      if (audioPlayerRef.current.audio.current.paused) {
        audioPlayerRef.current.audio.current.play();
      } else {
        audioPlayerRef.current.audio.current.pause();
      }
    }
  };

  useEffect(() => {
    const audioElement = audioPlayerRef.current?.audio.current;
    if (audioElement) {
      const handleTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
        setDuration(audioElement.duration);
      };
      audioElement.addEventListener('timeupdate', handleTimeUpdate);
      return () => audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    }
  }, []);

  return (
    <div className="h-[40px] w-full rounded-[30px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white px-[15px] flex items-center gap-[10px]">
      <div onClick={handlePlayPause} className="cursor-pointer">
        {isPlaying ? (
          <FaPause className="h-5 w-5" />
        ) : (
          <FaPlay className="h-5 w-5" />
        )}
      </div>
      <div className="flex flex-col justify-center w-full mt-1">
        <div className="h-2 bg-gray-400 rounded-full w-full">
          <div className="h-full bg-white rounded-full" style={{ width: `${(currentTime / duration) * 100}%` }}></div>
        </div>
        <div className="text-xs text-gray-300 flex justify-between w-full">
          <p>{formatTime(currentTime)}</p>
          <p>{formatTime(duration)}</p>
        </div>
      </div>
      <AudioPlayer
        src={sampleAudio}
        autoPlay={false}
        showJumpControls={false}
        style={{ display: 'none' }}
        ref={audioPlayerRef}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </div>
  );
};

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export default SampleAudioComponent;
