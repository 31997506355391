import React from 'react'
import PageLayout from '../../components/layouts/pageLayout'
import { Link } from 'react-router-dom';
import RecentTransactionsTable from '../earnings/recentTransactionsTable';
import TotalEarnings from './components/totalEarnings';
import MostPerformingStory from './components/mostPerformingStory';
import MostPerformingBook from './components/mostPerformingBook';
import MostPerformingPodcast from './components/mostPerformingPodcast';
import PublishedStories from './components/publishedStories';
import PublishedBooks from './components/publishedBooks';
import PublishedPodcast from './components/publishedPodcast';
import NewPublications from './newPublications/newPublications';


const Dashboard = () => {
    return (
        <>
            <PageLayout dashActive="active">
                <div className='w-full px-5 pt-5 pb-20 h-full text-black text-opacity-[80%] overflow-y-auto'>
                    <p className='text-[22px] font-[600]'>Dashboard</p>

                    {/* cards */}
                    <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-4 w-full mt-10 gap-5">
                        {/* 1 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <PublishedStories />
                        </div>

                        {/* 2 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <PublishedBooks />
                        </div>

                        {/* 3 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <PublishedPodcast />
                        </div>

                        {/* 4 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <TotalEarnings />
                        </div>
                    </div>

                    {/* stats */}
                    <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-3 w-full gap-5 mt-10">
                        {/* 1 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <MostPerformingStory />
                        </div>

                        {/* 2 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <MostPerformingBook />
                        </div>

                        {/* 3 */}
                        <div className="2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-5">
                            <MostPerformingPodcast />
                        </div>
                    </div>

                    {/* new publications */}
                    <div className="w-full mt-10 border border-gray-300 p-5 rounded shadow-sm">
                        <div className='flex justify-between items-center'>
                            <p className='text-[18px] font-[500]'>New Publications</p>

                            <Link to="/my-publications" className='px-4 h-[40px] border border-defaultPurple flex justify-center items-center rounded text-defaultPurple hover:text-white hover:bg-defaultPurple'>
                                View All
                            </Link>
                        </div>

                        <div className="mt-5">
                            <NewPublications />
                        </div>
                    </div>

                    {/* recent transactions */}
                    <div className="w-full mt-10 border border-gray-300 p-5 rounded shadow-sm">
                        <div className='flex justify-between items-center'>
                            <p className='text-[18px] font-[500]'>Recent Transactions</p>

                            <Link to="/all-transactions" className='px-4 h-[40px] border border-defaultPurple flex justify-center items-center rounded text-defaultPurple hover:text-white hover:bg-defaultPurple'>
                                View All
                            </Link>
                        </div>

                        <div className="mt-5">
                            <RecentTransactionsTable />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default Dashboard
