import React from 'react'
import { Select } from 'antd'


interface rangeProps {
    onChange?: (data: any) => void
}


const PeriodRangeFilter = ({ onChange }: rangeProps) => {
    return (
        <>
            <Select
                className='w-full h-[50px]'
                onChange={onChange}
                options={[
                    { label: 'Hourly', value: 'hourly' },
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Quarterly', value: 'quarterly' },
                    { label: 'Yearly', value: 'yearly' }
                ]}
                defaultValue='daily'
                placeholder="Select Range"
            />
        </>
    )
}

export default PeriodRangeFilter
