import React, { useEffect, useState } from 'react'
import { Empty, Modal, Spin, Switch, message } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { BiPlusCircle } from 'react-icons/bi';

import AddStoryChapter from './addStoryChapter';
import SingleChapterComponent from './singleChapterComponent';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import SampleAudioComponent from '../sampleAudioComponent';
import { useGeneralContext } from '../../../context/generalContext';



interface modalProps {
    isOpened: boolean
    handleClose: () => void
    tempBookData: any
    handleClosePrev: () => void
}

const FinishPublishingStory = ({ isOpened, handleClose, handleClosePrev, tempBookData }: modalProps) => {

    const { reFetch, setReFetch } = useGeneralContext()
    const [isPublishing, setIsPublishing] = useState(false)
    const [savingDraft, setSavingDraft] = useState(false)
    const [fetchingBookChapters, setFetchingBookChapters] = useState(false)
    const [bookChapters, setBookChapters] = useState([])
    const [reFetchChapters, setReFetchChapters] = useState(false)

    //openAddChapter
    const [openAddChapter, setOpenAddChapter] = useState(false)
    const handleOpenAddChapter = () => {
        setOpenAddChapter(true)
    }
    const handleCloseAddChapter = () => {
        setOpenAddChapter(false)
    }

    //onMount
    useEffect(() => {
        if (tempBookData) {
            console.log(tempBookData)
            getBookChapters();
        }

        // eslint-disable-next-line
    }, [reFetchChapters])

    //getBookChapters
    const getBookChapters = async () => {
        setFetchingBookChapters(true)
        try {
            const response = await BaseServices.get(`${global_variables.retrieve_single_book}/${tempBookData?.id}/chapter/list/`)
            console.log("---bookChapters:", response?.data)
            setBookChapters(response?.data)
            setFetchingBookChapters(false)
        } catch (error) {
            console.log(error)
        }
    }


    //handlePublish
    const handlePublish = () => {
        let data = new FormData();
        data.append('title', tempBookData?.title);
        data.append('category_ids', tempBookData?.categories.map((item: any) => (item?.id)));
        data.append('status', 'Published');
        data.append('type', tempBookData?.type);

        setIsPublishing(true)

        BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
            .then((response) => {
                console.log("create_story_success:", response)
                message.success("New story was published successfully!")
                setIsPublishing(false)
                setReFetch(!reFetch)
                handleClose()
                handleClosePrev()
            })
            .catch((error) => {
                setIsPublishing(false)
                console.log("create_story_error:", error)
                message.error(error?.details || error?.response?.data?.detail || "An error occured, please try again")
            });
    }

    //handleSaveAsDraft
    const handleSaveAsDraft = () => {
        let data = new FormData();
        data.append('title', tempBookData?.title);
        data.append('category_ids', tempBookData?.categories.map((item: any) => (item?.id)));
        data.append('status', 'Draft');
        data.append('type', tempBookData?.type);


        setSavingDraft(true)

        BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
            .then((response) => {
                console.log("update_story_success:", response)
                message.success("Story was saved as draft!")
                setSavingDraft(false)
                setReFetch(!reFetch)
                handleClose()
                handleClosePrev()
            })
            .catch((error) => {
                setSavingDraft(false)
                console.log("update_story_error:", error)
                message.error(error?.detail || error?.response?.data?.detail || "An error occured, please try again")
            });

    }


    return (
        <>
            <Modal width={1000} open={isOpened} onCancel={handleClose} footer={null} maskClosable={false} keyboard={false}>
                <p className='text-[26px] font-[700]'>Publish A Story / Book</p>

                <div className='text-black text-opacity-[80%] my-5 px-5 lg:grid lg:grid-cols-2 w-full gap-[100px]'>
                    {/* left */}
                    <div className="w-full">
                        <div>
                            <p className='text-[16px] font-[700]'>Title</p>
                            <p className='text-[16px] font-[400]'>{tempBookData?.title}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Author</p>
                            <p className='text-[16px] font-[400]'>{tempBookData?.author}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Narrated / Performed By</p>
                            <p className='text-[16px] font-[400]'>{tempBookData?.narrated_by}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>About</p>
                            <p className='text-[16px] font-[400]'>{tempBookData?.about}</p>
                        </div>

                        {
                            tempBookData?.categories &&
                            <div className='mt-[15px]'>
                                <p className='text-[16px] font-[700]'>Category</p>
                                <p className='text-[16px] font-[400]'>
                                    {
                                        tempBookData?.categories.map((item: any, i: number) => (
                                            item?.name + ", "
                                        ))
                                    }
                                </p>
                            </div>
                        }

                        {/* cover image */}
                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Cover Image</p>
                            <div className='mt-[6px]'>
                                <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={tempBookData?.cover_image || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />
                            </div>
                        </div>

                        {/* banner image */}
                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Banner Image</p>
                            <div className='mt-[6px]'>
                                <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={tempBookData?.banner || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />
                            </div>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700] mb-[10px]'>Audio Book / Play Sample</p>

                            {/* sample audio player component */}
                            {
                                tempBookData?.audio_sample &&
                                <SampleAudioComponent sampleAudio={tempBookData?.audio_sample} />
                            }

                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full">
                        <div className="flex justify-between items-center">
                            <p className='text-[16px] font-[700]'>Add a Chapter</p>
                            <BiPlusCircle className='h-[24px] w-[24px] text-black text-opacity-[80%] cursor-pointer' onClick={handleOpenAddChapter} />
                        </div>
                        <p className='text-[13px] font-[400] mt-[4px] text-[#A9B0B7]'>There are no chapters added in this story / book</p>


                        {/* added chapters */}
                        {
                            fetchingBookChapters ?
                                <div className='flex justify-center items-center my-10'>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: "black" }} spin />} />
                                </div>
                                :
                                <>
                                    {
                                        bookChapters.length === 0 ?
                                            <div className='mt-10'>
                                                <Empty description="No chapters added" />
                                            </div>
                                            :
                                            <div className="mt-5 w-full border-[1px] border-[#D7DBE1] rounded-[15px] p-[15px] shadow-sm">
                                                <p className='text-[16px] font-[700] text-black text-opacity-[80%]'>Added Chapters</p>

                                                {
                                                    bookChapters.map((chapter: any, i: number) => (
                                                        <div key={i}>
                                                            <SingleChapterComponent chapterInfo={chapter} reFetchChapters={() => setReFetchChapters(!reFetchChapters)} />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }

                                    {/* publish & draft buttons */}
                                    {
                                        bookChapters.length !== 0 &&
                                        <>
                                            <div className="mt-[42px] flex gap-[70px] items-center">
                                                <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handleSaveAsDraft} disabled={isPublishing || savingDraft}>
                                                    {
                                                        savingDraft ?
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                            :
                                                            <p className='text-[16px] font-[700]'>Draft</p>
                                                    }
                                                </button>

                                                <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handlePublish} disabled={isPublishing || savingDraft}>
                                                    {
                                                        isPublishing ?
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                            :
                                                            <p className='text-[16px] font-[700]'>Publish</p>
                                                    }
                                                </button>
                                            </div>
                                        </>
                                    }
                                </>
                        }

                    </div>
                </div>
            </Modal>


            {/* trigger chapter modal */}
            {
                openAddChapter && <AddStoryChapter isOpened={openAddChapter} handleCloseAddChapter={handleCloseAddChapter} bookId={tempBookData?.id} handleReFetch={() => setReFetchChapters(!reFetchChapters)} />
            }
        </>
    )
}

export default FinishPublishingStory
