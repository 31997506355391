import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


interface spinProps {
    spin?: boolean
    style?: any
}


const CustomSpinner = ({ spin, style }: spinProps) => {
    return (
        <>
            <Spin indicator={<LoadingOutlined style={style} spin={spin} />} />
        </>
    )
}

export default CustomSpinner
