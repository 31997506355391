import * as yup from "yup";

export const CreateStorySchema = yup.object().shape({
  title: yup.string().required("required"),
  author: yup.string().required("required"),
  narratedBy: yup.string().required("required"),
  about: yup.string().required("required"),
  type: yup.string().required("required"),
  category: yup.array().of(yup.number()).notRequired(),
  cover: yup.string().required("required"),
  sampleAudio: yup.string().required("required"),
  authorNotes: yup.string().notRequired(),
  banner: yup.string().notRequired(),
  language: yup.string().notRequired(),
});

export const CreateChapterSchema = yup.object().shape({
  title: yup.string().required("required"),
  number: yup.string().required("required"),
});

export const CreatePodcastSchema = yup.object().shape({
  title: yup.string().required("required"),
  sub_title: yup.string().required("required"),
  about: yup.string().required("required"),
  hosts: yup.string().required("required"),
  language_ids: yup.number().notRequired(),
  category_ids: yup.array().of(yup.number()).required("required"),
  status: yup.string().notRequired(),
});

export const CreatePodcastEpisodeSchema = yup.object().shape({
  description: yup.string().required("required"),
  number: yup.string().required("required"),
});
