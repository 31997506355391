import React, { useState } from 'react'
import logo from "../../../assets/BUZS.png"
import { Form, Input, message, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Link } from 'react-router-dom'
import { BaseServicesDefault } from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { GoEye, GoEyeClosed } from "react-icons/go";
import { StorageBox } from '../../../helpers/stroage'
import { WEBSITE_URL } from '../../../helpers/constants'



const SigninPage = () => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [viewPass, setViewPass] = useState(false)


    //handle sign in
    const onFormFinished = async (values: any) => {
        console.log("formValues:", values)

        let signin_info = {
            "username": values?.username,
            "password": values?.password
        }
        console.log("signin_info:", signin_info)

        setIsBusy(true)

        try {
            const response = await BaseServicesDefault.post(global_variables.sign_in, signin_info)
            console.log("signinRes:", response?.data)
            message.success(`Logged in as ${response?.data?.user?.profile?.name || 'unkown user'}`)
            StorageBox.saveAccessToken(response?.data?.auth_token)
            StorageBox.saveUserData(response?.data?.user)
            setTimeout(() => {
                window.location.href = `/authorization/${response?.data?.user?.profile?.id}`
            }, 1500)
        } catch (error: any) {
            console.log("signInErr:", error)
            message.error(error?.response?.data?.detail || "An unknown error occured. Try again")
            setIsBusy(false)
        }
    }

    return (
        <>
            <div className="h-screen w-full overflow-hidden">
                <div className="w-full 2xl:grid xl:grid lg:grid grid-cols-2 h-full bg-gradient-to-b from-purple-300 to-defaultPurple 2xl:px-[250px] xl:px-[150px] lg:px-[100px] md:px-[20px] px-[10px]">
                    {/* left */}
                    <div className="w-full h-full 2xl:flex xl:flex lg:flex justify-center items-center hidden">
                        <div>
                            <img className='w-[350px]' src={logo} alt="" />
                            <p className='text-[32px] font-bold text-center text-white'>BUZSQUARE</p>
                            <p className='text-[16px] text-white mt-2'>Professional Account for Publishers and Content Owners</p>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full flex justify-center items-center h-full overflow-y-auto px-5">
                        <div className="w-[500px] bg-white shadow-md p-10 rounded-[30px] text-black text-opacity-[80%]">
                            <p className='text-[24px] font-[600]'>Sign In</p>
                            <p className='text-gray-500'>Get access to your professional account by providing your email and password</p>

                            <div className="mt-5 w-full">
                                <Spin spinning={isBusy}>
                                    <Form
                                        form={form}
                                        name="loginForm"
                                        onFinish={onFormFinished}
                                        layout="vertical"
                                    >
                                        <Form.Item
                                            label="Email"
                                            name="username"
                                            rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
                                        >
                                            <Input className='h-[45px] rounded-[15px]' placeholder='example@gmail.com' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[{ required: true, message: 'Please input your password!' }]}
                                        >
                                            <div className='relative'>
                                                <Input className='h-[45px] rounded-[15px]' type={viewPass ? 'text' : 'password'} placeholder='example@gmail.com' />
                                                {
                                                    viewPass ?
                                                        <GoEyeClosed className='h-5 w-5 absolute right-3 bottom-3 cursor-pointer text-gray-500' onClick={() => setViewPass(false)} />
                                                        :
                                                        <GoEye className='h-5 w-5 absolute right-3 bottom-3 cursor-pointer text-gray-500' onClick={() => setViewPass(true)} />
                                                }
                                            </div>
                                        </Form.Item>

                                        <div className="mt-10 w-full">
                                            <p>
                                                Forgot password?
                                                <span>
                                                    <Link to={`${WEBSITE_URL}/forgot-password`} target='_blank' className='font-[600] text-defaultPurple ml-2'>Reset Password</Link>
                                                </span>
                                            </p>
                                        </div>

                                        <Form.Item className='mt-8'>
                                            <button type='submit' className='h-[50px] w-full rounded-[40px] bg-gradient-to-r from-purple-400 to-purple-400 via-defaultPurple text-white'>
                                                <p className='text-[16px] font-[700]'>Sign In</p>
                                            </button>
                                        </Form.Item>

                                        {/* <div className="mt-5 w-full">
                                            <p>
                                                Don't have a professional account?
                                                <span>
                                                    <Link to={`${WEBSITE_URL}/signup`} target='_blank' className='font-[600] text-defaultPurple ml-2'>Sign Up</Link>
                                                </span>
                                            </p>
                                        </div> */}
                                    </Form>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SigninPage
