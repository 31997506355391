import axios from "axios";
import { API_BASE_URL } from "./constants";
import { StorageBox } from "./stroage";

const token = StorageBox.getAccessToken();

export const BaseServices = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    Authorization: `Token ${token}`,
  },
});

export const BaseServicesDefault = axios.create({
  baseURL: `${API_BASE_URL}`,
});

export const BaseServicesPostForm = axios.create({
  timeout: 10000000,
  baseURL: `${API_BASE_URL}`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${token}`,
  },
});
