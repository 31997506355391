import axios from "axios";
import { API_BASE_URL } from "../helpers/constants";
import { StorageBox } from "../helpers/stroage";

export const GetSignedUrl = async (fileName: any, object_type: any) => {
  let data = JSON.stringify({
    [object_type]: [fileName],
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_BASE_URL}/v1/aws/upload/url/generate/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${StorageBox.getAccessToken()}`,
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    console.log("firstResponse:", response);
    return response?.data?.presigned_urls?.[`${object_type}`]?.[`${fileName}`];
  } catch (error) {
    return error;
  }
};
