export const global_variables = {
  //auth
  sign_in: "/v1/account/user/authenticate/",
  user_profile: "/v1/account/user/profile/",
  create_prof_account: "/v1/account/user/professional/create/",

  //publications
  list_audio_book: "/v1/library/audio-book/list/",
  list_podcasts: "/v1/library/podcast/list/",
  retrieve_single_book: "/v1/library/audio-book",
  banner_categories: "/v1/library/category/list",
  audio_book_url: "/v1/library/audio-book",
  podcats_url: "/v1/library/podcast",
  all_podcast_list: "/v1/library/podcast/list",
  create_audio_book: "/v1/library/audio-book/create/",

  get_total_earnings: "/v1/subscription/total-earning/retrieve/",
  get_all_subscriptions: "/v1/subscription/list",
  get_single_subscription: "/v1/subscription/2/retrieve/",
  most_performing: "/v1/subscription/performing-content/list/",

  // chart
  get_chart_data: "/v1/dashboard/total-subscribers/retrieve/",
  get_total_listens: "/v1/dashboard/total-listens/retrieve/",
};
