import React from 'react'
import moment from 'moment';
import { Chart } from 'react-google-charts';


interface theProps {
    results: any
}


const SubscribersChart = ({ results }: theProps) => {


    const data = [
        ['Date', 'Value'],
        ...(results?.labels ? results.labels.map((label: any, index: number) => (
            [moment(label).format("Do MMM"), results.total_subscribers[index]]
        )) : [])
    ];


    const options = {
        title: '',
        hAxis: { title: 'Date' },
        vAxis: { title: 'Subscribers', minValue: 0 },
        legend: 'none',
    };

    return (
        <>
            <div className='w-full'>
                {results?.labels && results?.labels.length > 0 && (
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />
                )}
            </div>
        </>
    )
}

export default SubscribersChart
