"use client"

import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { BaseServices } from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'


interface theProps {
    onChange: (selected: any) => void
    defaultValue?: any
}


const BookCategorySelector = ({ onChange, defaultValue }: theProps) => {

    const [isFetching, setIsFetching] = useState(false)
    const [allCategories, setAllCategories] = useState([])


    //on page mount
    useEffect(() => {
        const getAllCategories = async () => {
            setIsFetching(true)
            try {
                const response = await BaseServices.get(`${global_variables.banner_categories}`)
                console.log("categoryList:", response)
                if (response?.data) {
                    const arrangedResponse = response?.data.map((item: any) => (
                        { label: item?.name, value: item?.id }
                    ))
                    setAllCategories(arrangedResponse)
                    setIsFetching(false)
                }
            } catch (error) {
                console.log(error)
            }
        }

        getAllCategories()
    }, [])


    return (
        <>
            <Select id="category" mode='multiple' className='h-full w-full' variant='borderless'
                options={allCategories}
                onChange={onChange}
                disabled={isFetching}
                loading={isFetching}
                defaultValue={defaultValue}
                virtual={false}
            />
        </>
    )
}

export default BookCategorySelector
