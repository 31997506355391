
import { Collapse, Empty, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BiPencil, BiPlusCircle, BiTrash } from 'react-icons/bi';
import { LoadingOutlined } from '@ant-design/icons';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import AddStoryEpisodes from './addStoryEpisodes';
import SampleAudioComponent from '../sampleAudioComponent';
import EditStoryChapter from './editStoryChapter';


interface theProps {
    chapterInfo: any
    reFetchChapters: () => void
}

const SingleChapterComponent = ({ chapterInfo, reFetchChapters }: theProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [fetchingEpisodes, setFetchingEpisodes] = useState(false)
    const [episodesList, setEpisodesList] = useState([])
    const [reFetchEpisodes, setReFetchEpisodes] = useState(false)

    //open edit modal
    const [openAddEpisode, setOpenAddEpisode] = useState(false)
    const handleOpenAddEpisode = () => {
        setOpenAddEpisode(true)
    }
    const handleCloseAddEpisode = () => {
        setOpenAddEpisode(false)
    }

    //open edit modal
    const [openEditChapter, setOpenEditChapter] = useState(false)
    const handleOpenEdit = () => {
        setOpenEditChapter(true)
    }
    const handleCloseEdit = () => {
        setOpenEditChapter(false)
    }

    //getChapterEpisodes
    const getChapterEpisodes = async () => {
        setFetchingEpisodes(true)
        try {
            const response = await BaseServices.get(`${global_variables.audio_book_url}/chapter/${chapterInfo?.id}/episode/list/`)
            console.log("-----chapterEpisodes:", response)
            setEpisodesList(response?.data)
            setFetchingEpisodes(false)
        } catch (error) {
            console.log(error)
        }
    }

    //onload
    useEffect(() => {
        getChapterEpisodes()

        // eslint-disable-next-line
    }, [reFetchEpisodes])


    //handleDeleteChapter
    const handleDeleteChapter = async () => {
        setIsBusy(true)
        try {
            const response = await BaseServices.delete(`${global_variables.audio_book_url}/${chapterInfo?.audio_book?.id}/chapter/${chapterInfo?.id}/delete/`)
            console.log("delRes:", response)
            setIsBusy(false)
            message.success("Chapter was deleted successfully!")
            reFetchChapters()
        } catch (error: any) {
            console.log("-----delEpisodeError:", error)
            setIsBusy(false)
            message.error(error?.details || error?.response?.data?.error || error?.response?.data?.detail || "An error occured, please try again")
        }
    }


    //dropdown for episodes
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenAddEpisode}>
                <BiPlusCircle className='h-5 w-5' />
                <p>Add Episodes</p>
            </button>,
            key: '0',
        },
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleOpenEdit}>
                <BiPencil className='h-5 w-5' />
                <p>Edit</p>
            </button>,
            key: '1',
        },
        {
            label: <button className='flex gap-2 items-center text-red-500' onClick={handleDeleteChapter}>
                <BiTrash className='h-5 w-5' />
                <p>Delete</p>
            </button>,
            key: '2',
        },
    ];



    return (
        <>
            <div className="mt-[10px] rounded-[15px] bg-[#ECEDF3] p-[10px] text-black text-opacity-[70%]">
                <div className='flex justify-between items-center'>
                    <p className='text-[13px] font-[700]'>Chapter {chapterInfo?.number}</p>
                    {
                        isBusy ?
                            <>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "#000" }} spin />} />
                            </>
                            :
                            <Dropdown menu={{ items }} trigger={['click']} placement='bottomCenter' disabled={fetchingEpisodes}>
                                <button onClick={(e) => e.preventDefault()}>
                                    <BsThreeDots className='h-5 w-5' />
                                </button>
                            </Dropdown>
                    }
                </div>

                <div className='mt-[6px]'>
                    <Collapse
                        items={[
                            {
                                label: <p className='text-[16px] font-[400]'>{chapterInfo?.title}</p>,
                                children: <>
                                    {
                                        episodesList.length === 0 ? <>
                                            <Empty description="No episodes added" />
                                        </>
                                            :
                                            episodesList.map((episode: any, i: number) => (
                                                <div key={i} className='rounded-[10px] bg-white p-[10px] flex gap-2 items-center justify-between mb-2'>
                                                    <p className='w-full line-clamp-1'>EP{episode?.number} - {episode?.title} </p>
                                                    <SampleAudioComponent sampleAudio={episode?.audio} />
                                                </div>
                                            ))
                                    }
                                </>
                            }
                        ]}
                        ghost
                        expandIconPosition='end'
                    />
                </div>
            </div>


            {/* add episode */}
            {
                openAddEpisode && <AddStoryEpisodes isOpened={openAddEpisode} handleCloseAddEpisode={handleCloseAddEpisode} handleReFetch={() => setReFetchEpisodes(!reFetchEpisodes)} chapterInfo={chapterInfo} />
            }

            {/* the edit modal */}
            {
                openEditChapter && <EditStoryChapter isOpened={openEditChapter} handleCloseEditChapter={handleCloseEdit} handleReFetch={reFetchChapters} chapterInfo={chapterInfo} />
            }
        </>
    )
}

export default SingleChapterComponent
