import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { BaseServices } from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { LuPodcast } from 'react-icons/lu'
import { useGeneralContext } from '../../../context/generalContext'

const PublishedPodcast = () => {

    const { reFetch } = useGeneralContext()
    const [isFetching, setIsFetching] = useState(true)
    const [pubPodcast, setPubPodcast] = useState<any>(0)

    //onload
    useEffect(() => {
        const getPubPodcast = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.list_podcasts}?limit=1&status=Published`)
                // console.log("pubPodcast:", response?.data)
                setPubPodcast(response?.data?.count)
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getPubPodcast()
    }, [reFetch])


    return (
        <>
            {
                isFetching ?
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm">
                        <div>
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>
                    </div>
                    :
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm flex justify-between items-center">
                        <div>
                            <p className='text-[18px] font-[500]'>Published Podcasts</p>

                            <p className='text-[24px] font-[600] mt-3'>
                                {pubPodcast}
                            </p>
                        </div>

                        <div>
                            <LuPodcast className='h-10 w-10' />
                        </div>
                    </div>
            }
        </>
    )
}

export default PublishedPodcast
