import React, { useEffect, useState } from 'react'
import { Collapse, Input, Modal, Spin, Switch, message } from 'antd';
import { BsThreeDots } from "react-icons/bs";
import { LoadingOutlined } from '@ant-design/icons';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { useFormik } from 'formik';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import { GetSignedUrl } from '../../../functions/getSignedAudioUrl';
import { DoSignedAudioUpload } from '../../../functions/doSignedAudioUpload';
import { CreatePodcastEpisodeSchema } from '../../../form-schema/story.schema';
import AudioSampleUploader from '../../mediaUploader/audioSampleUploader';
import SampleAudioComponent from '../sampleAudioComponent';
import { useGeneralContext } from '../../../context/generalContext';



interface modalProps {
    isOpened: boolean
    handleClose: () => void
    tempPodcastData: any
    handleClosePrev: () => void
}

const FinishPublishingPodcast = ({ isOpened, handleClose, handleClosePrev, tempPodcastData }: modalProps) => {

    const { reFetch, setReFetch } = useGeneralContext()
    const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [audioFileName, setAudioFileName] = useState<any>("")

    const [isPublishing, setIsPublishing] = useState(false)
    const [savingDraft, setSavingDraft] = useState(false)

    const [isCreatingEpisode, setIsCreatingEpisode] = useState(false)
    const [allEpisodes, setAllEpisodes] = useState([])
    const [reFetchEpisodes, setReFetchEpisodes] = useState(false)

    const [selectedEpisode, setSelectedEpisode] = useState<any>(null)
    const [isBusy, setIsBusy] = useState(false)
    const [isEditing, setIsEditing] = useState(false)



    //onMount
    useEffect(() => {
        if (tempPodcastData) {
            console.log(tempPodcastData)
            getPodcastEpisode()
        }

        // eslint-disable-next-line
    }, [reFetchEpisodes])

    //getPodcastEpisode
    const getPodcastEpisode = async () => {
        try {
            const response = await BaseServices.get(`${global_variables.podcats_url}/${tempPodcastData?.id}/episode/list/`)
            console.log("---episodesList:", response?.data)
            setAllEpisodes(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    //handleAudioFileChange
    const handleAudioFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            console.log("audioExtension:", file)
            setAudioFileName(file?.name)
            setSelectedAudioFile(file);
            const url = URL.createObjectURL(file);
            setAudioUrl(url);
        }
    };


    //handlePublish
    const handlePublish = () => {
        let data = new FormData();
        data.append('title', tempPodcastData?.title);
        data.append('sub_title', tempPodcastData?.sub_title);
        data.append('about', tempPodcastData?.about);
        data.append('category_ids', tempPodcastData?.categories.map((item: any) => (item?.id)));
        data.append('language_ids', tempPodcastData?.language);
        data.append('status', 'Published');

        setIsPublishing(true)

        BaseServices.patch(`${global_variables.podcats_url}/${tempPodcastData?.id}/update/`, data)
            .then((response) => {
                console.log("update_pordcast_success:", response)
                message.success("Podcast was published successfully!")
                setIsPublishing(false)
                setReFetch(!reFetch)
                handleClose()
                handleClosePrev()
            })
            .catch((error) => {
                setIsPublishing(false)
                console.log("update_podcast_error:", error)
                message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
            });
    }

    //handleSaveAsDraft
    const handleSaveAsDraft = () => {
        let data = new FormData();
        data.append('title', tempPodcastData?.title);
        data.append('sub_title', tempPodcastData?.sub_title);
        data.append('about', tempPodcastData?.about);
        data.append('category_ids', tempPodcastData?.categories.map((item: any) => (item?.id)));
        data.append('language_ids', tempPodcastData?.language);
        data.append('status', 'Draft');

        setSavingDraft(true)

        BaseServices.patch(`${global_variables.podcats_url}/${tempPodcastData?.id}/update/`, data)
            .then((response) => {
                console.log("update_pordcast_success:", response)
                message.success("Podcast was saved as draft!")
                setSavingDraft(false)
                setReFetch(!reFetch)
                handleClose()
                handleClosePrev()
            })
            .catch((error) => {
                setSavingDraft(false)
                console.log("update_podcast_error:", error)
                message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
            });
    }

    //handleCreateEpisode
    const onSubmit = async () => {
        if (values?.number && values?.description && selectedAudioFile) {
            //creating new episode
            let data = new FormData();
            data.append('title', tempPodcastData?.title);
            data.append('description', values?.description);
            data.append('number', values?.number);

            setIsCreatingEpisode(true)

            //get audio upload url
            GetSignedUrl(audioFileName, "podcast_episode")
                .then((signedRes) => {
                    console.log("signedRes:", signedRes)
                    //signed upload
                    DoSignedAudioUpload(signedRes?.upload_url, selectedAudioFile, signedRes?.content_type)
                        .then((uploadRes: any) => {
                            console.log("uploadRes:", uploadRes)
                            if (uploadRes?.status === 200) {
                                data.append('audio', signedRes?.media_url);

                                //create actual episode
                                BaseServices.post(`${global_variables.podcats_url}/${tempPodcastData?.id}/episode/create/`, data)
                                    .then((response) => {
                                        console.log("podcast_episode_success:", response)
                                        message.success("New episode was added to podcast!")
                                        setIsCreatingEpisode(false)
                                        setReFetchEpisodes(!reFetchEpisodes)
                                        values.number = ""
                                        values.description = ""
                                        setSelectedAudioFile(null)
                                        setAudioUrl(null)
                                    })
                                    .catch((error) => {
                                        setIsCreatingEpisode(false)
                                        console.log("podcast_episode_error:", error)
                                        message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
                                    });
                            } else {
                                setIsCreatingEpisode(false)
                                message.error("Audio upload failed, please restart the process.")
                            }
                        })
                        .catch((uploadErr) => {
                            //signed upload err
                            setIsCreatingEpisode(false)
                            console.log("uploadErr:", uploadErr)
                            message.error(uploadErr?.details || uploadErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                        })
                }).catch((signedErr) => {
                    //get audio upload url error
                    setIsCreatingEpisode(false)
                    console.log("signedErr:", signedErr)
                    message.error(signedErr?.details || signedErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                })

        }
    }

    //handleUpateEpisode
    const onSubmitUpdate = async () => {
        //updating existing episode
        let data = new FormData();
        data.append('title', selectedEpisode?.title);
        data.append('description', values?.description);
        values?.number !== selectedEpisode?.number && data.append('number', values?.number);

        setIsCreatingEpisode(true)

        //get audio upload url
        GetSignedUrl(audioFileName, "podcast_episode")
            .then((signedRes) => {
                console.log("signedRes:", signedRes)
                //signed upload
                DoSignedAudioUpload(signedRes?.upload_url, selectedAudioFile, signedRes?.content_type)
                    .then((uploadRes: any) => {
                        console.log("uploadRes:", uploadRes)
                        if (uploadRes?.status === 200) {
                            data.append('audio', signedRes?.media_url);
                            data.append('edit_audio', `${true}`);

                            //start update episode            
                            BaseServices.patch(`${global_variables.podcats_url}/${tempPodcastData?.id}/episode/${selectedEpisode?.id}/update/`, data)
                                .then((response) => {
                                    console.log("update_episode_success:", response)
                                    message.success("Episode was updated successfully!")
                                    setIsCreatingEpisode(false)
                                    setReFetchEpisodes(!reFetchEpisodes)
                                    values.number = ""
                                    values.description = ""
                                    setIsEditing(false)
                                    setSelectedAudioFile(null)
                                    setAudioUrl(null)
                                })
                                .catch((error) => {
                                    setIsCreatingEpisode(false)
                                    console.log("update_episode_error:", error)
                                    message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
                                });
                        } else {
                            data.append('audio', selectedEpisode?.audio);
                            //start update episode            
                            BaseServices.patch(`${global_variables.podcats_url}/${tempPodcastData?.id}/episode/${selectedEpisode?.id}/update/`, data)
                                .then((response) => {
                                    console.log("update_episode_success:", response)
                                    message.success("Episode was updated successfully!")
                                    setIsCreatingEpisode(false)
                                    setReFetchEpisodes(!reFetchEpisodes)
                                    values.number = ""
                                    values.description = ""
                                    setIsEditing(false)
                                    setSelectedAudioFile(null)
                                    setAudioUrl(null)
                                })
                                .catch((error) => {
                                    setIsCreatingEpisode(false)
                                    console.log("update_episode_error:", error)
                                    message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
                                });
                        }
                    })
                    .catch((uploadErr) => {
                        //signed upload err
                        setIsCreatingEpisode(false)
                        console.log("uploadErr:", uploadErr)
                        message.error(uploadErr?.details || uploadErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                    })
            }).catch((signedErr) => {
                //get audio upload url error
                setIsCreatingEpisode(false)
                console.log("signedErr:", signedErr)
                message.error(signedErr?.details || signedErr?.response?.data?.error || "An error occured while uploading audio, please try again")
            })

    }

    //input validation
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            description: "",
            number: "",
        },
        validationSchema: CreatePodcastEpisodeSchema,
        onSubmit,
    });

    //handleDeleteEpisode
    const handleDeleteEpisode = async () => {
        console.log(selectedEpisode)
        setIsBusy(true)
        try {
            const response = await BaseServices.delete(`${global_variables.podcats_url}/${selectedEpisode?.podcast?.id}/episode/${selectedEpisode?.id}/delete/`)
            console.log("----delEpisodeResponse:", response)
            setIsBusy(false)
            message.success("Episode was deleted successfully!")
            setReFetchEpisodes(!reFetchEpisodes)
        } catch (error: any) {
            console.log("-----delEpisodeError:", error)
            setIsBusy(false)
            message.error(error?.details || error?.response?.data?.error || "An error occured, please try again")
        }
    }

    //handleSetUpdateInfo
    const handleSetUpdateInfo = () => {
        setIsEditing(true)
        values.number = selectedEpisode?.number
        values.description = selectedEpisode?.description
        setAudioUrl(selectedEpisode?.audio)
    }


    //dropdown for episodes
    const items: MenuProps['items'] = [
        {
            label: <button className='flex gap-2 items-center text-black text-opacity-[80%]' onClick={handleSetUpdateInfo}>
                <BiPencil className='h-5 w-5' />
                <p>Edit</p>
            </button>,
            key: '0',
        },
        {
            label: <button className='flex gap-2 items-center text-red-500' onClick={handleDeleteEpisode}>
                <BiTrash className='h-5 w-5' />
                <p>Delete</p>
            </button>,
            key: '3',
        },
    ];


    return (
        <>
            <Modal width={1000} open={isOpened} onCancel={handleClose} footer={null} maskClosable={false} keyboard={false}>
                <p className='text-[26px] font-[700]'>Publish a podcast</p>

                <div className='text-black text-opacity-[80%] my-5 px-5 lg:grid lg:grid-cols-2 w-full gap-[100px]'>
                    {/* left */}
                    <div className="w-full">
                        <div>
                            <p className='text-[16px] font-[700]'>Title</p>
                            <p className='text-[16px] font-[400]'>{tempPodcastData?.title}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Sub title</p>
                            <p className='text-[16px] font-[400]'>{tempPodcastData?.sub_title}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>About The Podcast</p>
                            <p className='text-[16px] font-[400]'>{tempPodcastData?.about}</p>
                        </div>

                        {
                            tempPodcastData?.categories &&
                            <div className='mt-[15px]'>
                                <p className='text-[16px] font-[700]'>Category</p>
                                <p className='text-[16px] font-[400]'>
                                    {
                                        tempPodcastData?.categories.map((item: any, i: number) => (
                                            item?.name + ", "
                                        ))
                                    }
                                </p>
                            </div>
                        }

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Language</p>
                            <p className='text-[16px] font-[400]'>{tempPodcastData?.language?.name || "Unknown"}</p>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Podcast Cover</p>
                            <div className='mt-[6px]'>
                                <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={tempPodcastData?.cover_image || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />
                            </div>
                        </div>

                        <div className='mt-[15px]'>
                            <p className='text-[16px] font-[700]'>Banner Cover</p>
                            <div className='mt-[6px]'>
                                <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={tempPodcastData?.banner || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />
                            </div>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full">
                        <p className='text-[16px] font-[700]'>Add an episode</p>
                        {
                            allEpisodes.length === 0 && <p className='text-[13px] font-[400] mt-[4px] text-[#A9B0B7]'>There are no episodes added in this podcast</p>
                        }

                        <div className='mt-5'>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor='number' className='text-[16px] font-[400]'>Episode number {errors.number && touched.number && <small className='text-xs text-red-500'>required</small>}</label>
                                    <Input id="number" type='number' min={1} className={`${errors.number && touched.number && 'border-red-500'} w-full h-[52px] rounded-[20px] mt-[7px]`} onBlur={handleBlur} value={values.number} onChange={handleChange} disabled={isCreatingEpisode} />
                                </div>

                                <div className='mt-[15px]'>
                                    <label htmlFor='description' className='text-[16px] font-[400]'>Description {errors.description && touched.description && <small className='text-xs text-red-500'>required</small>}</label>
                                    <Input.TextArea style={{ height: '100px' }} id="description" className={`${errors.description && touched.description && 'border-red-500'} w-full h-[52px] rounded-[20px] mt-[7px]`} onBlur={handleBlur} value={values.description} onChange={handleChange} disabled={isCreatingEpisode} />
                                </div>

                                <div className="mt-[15px]">
                                    <small className='text-[16px] font-[400]'>Upload Episode <small className='text-xs text-red-500'>required</small></small>
                                    <AudioSampleUploader id="audioo" placeholder='Select file to upload' onChange={handleAudioFileChange} />
                                    {audioUrl &&
                                        <div className='mt-2 flex items-center gap-2'>
                                            <SampleAudioComponent sampleAudio={audioUrl} />
                                            <BiTrash className='h-5 w-5 text-red-500 cursor-pointer' onClick={() => {
                                                setSelectedAudioFile(null)
                                                setAudioUrl(null)
                                            }} />
                                        </div>
                                    }
                                </div>
                            </form>

                            <div className="mt-5">
                                <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' type='submit' onClick={isEditing ? onSubmitUpdate : onSubmit} disabled={isCreatingEpisode}>
                                    {
                                        isCreatingEpisode ?
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                            :
                                            <p className='text-[16px] font-[700]'>{isEditing ? "Save" : "Add"}</p>
                                    }
                                </button>
                            </div>

                            {/* added episodes */}
                            {
                                allEpisodes.length !== 0 &&
                                <div className="mt-5 w-full border-[1px] border-[#D7DBE1] rounded-[15px] p-[15px] shadow-sm">
                                    <p className='text-[16px] font-[700] text-black text-opacity-[80%]'>Added Episodes</p>

                                    {
                                        allEpisodes.map((item: any, i: number) => (
                                            <div key={i} className='mt-[10px]'>
                                                <div className="mt-[10px] rounded-[15px] bg-[#ECEDF3] p-[10px] text-black text-opacity-[70%]">
                                                    <div className='flex justify-between items-center'>
                                                        <p className='text-[13px] font-[700]'>Episode {item?.number}</p>
                                                        {
                                                            item?.id === selectedEpisode?.id && isBusy ?
                                                                <>
                                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "#000" }} spin />} />
                                                                </>
                                                                :
                                                                <Dropdown menu={{ items }} trigger={['click']} placement='bottomCenter'>
                                                                    <button onClick={() => setSelectedEpisode(item)}>
                                                                        <BsThreeDots className='h-5 w-5' />
                                                                    </button>
                                                                </Dropdown>
                                                        }
                                                    </div>

                                                    <div className='mt-[6px]'>
                                                        <Collapse
                                                            items={[
                                                                {
                                                                    label: <p className='text-[16px] font-[400] line-clamp-1'>{item?.description}</p>,
                                                                    children: <>
                                                                        <div className='rounded-[10px] bg-white p-[10px] flex gap-2 items-center justify-between mb-2'>
                                                                            <p className='w-full line-clamp-1'>Episode {item?.number} - {item?.description}</p>
                                                                            <SampleAudioComponent sampleAudio={item?.audio} />
                                                                        </div>
                                                                    </>
                                                                }
                                                            ]}
                                                            ghost
                                                            expandIconPosition='end'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }

                            {/* publish and save */}
                            <div className="mt-[30px] flex gap-[70px] items-center">
                                <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handleSaveAsDraft} disabled={isPublishing || savingDraft}>
                                    {
                                        savingDraft ?
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                            :
                                            <p className='text-[16px] font-[700]'>Draft</p>
                                    }
                                </button>

                                <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handlePublish} disabled={isPublishing || savingDraft}>
                                    {
                                        isPublishing ?
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                            :
                                            <p className='text-[16px] font-[700]'>Publish</p>
                                    }
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FinishPublishingPodcast
