import React, { useEffect, useState } from 'react'
import { Rate, Skeleton } from 'antd'
import { BaseServices } from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'
import { useGeneralContext } from '../../../context/generalContext'


const MostPerformingBook = () => {

    const { reFetch } = useGeneralContext()
    const [isFetching, setIsFetching] = useState(true)
    const [mostPerforming, setMostPerforming] = useState<any>(null)

    //onload
    useEffect(() => {
        const getMostPerforming = async () => {
            setIsFetching(true)

            try {
                const response = await BaseServices.get(`${global_variables.most_performing}?offset=0&limit=1&type=Book`)
                // console.log("performingBook:", response?.data)
                setMostPerforming(response?.data?.results?.[0])
                setIsFetching(false)
            } catch (error) {
                console.log(error)
            }
        }

        getMostPerforming()
    }, [reFetch])


    return (
        <>
            {
                isFetching ?
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm">
                        <Skeleton active paragraph={{ rows: 2 }} />

                        <hr className='my-5' />

                        <div className='mt-3'>
                            <Skeleton active paragraph={{ rows: 1 }} />
                        </div>

                        <hr className='my-5' />

                        <div className='mt-3 flex gap-5'>
                            <div>
                                <Skeleton.Image style={{ height: 85, width: 100 }} active />
                            </div>
                            <div className='w-full'>
                                <Skeleton active paragraph={{ rows: 2 }} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="w-full border border-gray-300 p-5 rounded shadow-sm">
                        <p className='text-[18px] font-[500]'>Most Performing Book</p>

                        <div className='mt-3'>
                            <p className='text-[16px] font-[400]'>Total Subscribers</p>
                            <p className='text-[24px] font-[600]'>
                                {mostPerforming?.total_subscribers || 0}
                            </p>
                        </div>

                        <hr className='my-5' />

                        <div className='mt-3'>
                            <p className='text-[16px] font-[400]'>Revenue</p>
                            <p className='text-[24px] font-[600]'>
                                GHS {mostPerforming?.total_revenue || 0}
                            </p>
                        </div>

                        <hr className='my-5' />

                        <div className='mt-3 flex gap-5 items-center'>
                            <div>
                                <img className='h-[85px] w-[100px] object-cover rounded-[5px]' src={mostPerforming?.audio_book?.cover_image || "https://isjade.simplify.cool/images/no-image-2.jpg"} alt="" />
                            </div>
                            <div>
                                <p className='text-[16px] font-[600]'>Title: {mostPerforming?.audio_book?.title}</p>
                                <p className='text-[14px] font-[400] mb-3'>By: {mostPerforming?.audio_book?.author}</p>
                                <Rate count={5} value={mostPerforming?.audio_book?.average_rating} disabled />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default MostPerformingBook
