import React from 'react'
import CustomNavbar from './customNavbar'
import CustomSideBar from './customSidebar'


interface layoutProps {
    children: any
    dashActive?: any
    earnActive?: any
    analyticsActive?: any
    pubsActive?: any
}


const PageLayout = ({ dashActive, earnActive, analyticsActive, pubsActive, children }: layoutProps) => {
    return (
        <div className='h-screen overflow-hidden'>
            <CustomNavbar />

            <div className="h-full flex">
                {/* sidebar */}
                <div className='h-full bg-white'>
                    <CustomSideBar dashActive={dashActive} earnActive={earnActive} analyticsActive={analyticsActive} pubsActive={pubsActive} />
                </div>

                <div className="h-full w-full pb-10">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageLayout
