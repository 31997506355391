import React, { useState } from 'react'
import { Input, Modal, Spin, Switch, Tooltip, message } from 'antd';
import { BiInfoCircle } from 'react-icons/bi';
import { useFormik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import { CreatePodcastSchema } from '../../../form-schema/story.schema';
import BookCategorySelector from '../bookCategorySelector';
import CoverImageUploader from '../../mediaUploader/coverImageUploader';
import BannerImageUploader from '../../mediaUploader/bannerImageUploader';
import FinishPublishingPodcast from './finishPublishingPodcast';
import LanguageSelector from '../languageSelector';



interface modalProps {
    isOpened: boolean
    handleClose: () => void
}

const PublishPodcastModal = ({ isOpened, handleClose }: modalProps) => {

    const [podcastCoverFile, setPodcastCoverFile] = useState<File | null>(null);
    const [podcastCoverUrl, setPodcastCoverUrl] = useState<string | null>(null);

    const [podcastBannerFile, setPodcastBannerFile] = useState<File | null>(null);
    const [podcastBannerUrl, setPodcastBannerUrl] = useState<string | null>(null);

    const [podcatsCategories, setPodCastCategories] = useState<any>([])
    const [bookLanguage, setBookLanguage] = useState("")
    const [bookPrice, setBookPrice] = useState("")

    const [isSavingPodcast, setIsSavingPodcast] = useState(false)
    const [tempPodcastData, setTempPodcastData] = useState<any>(null)

    const [publishToFeed, setPublishToFeed] = useState<boolean | any>(false)

    //podcast finish modal
    const [openFinishPodcast, setOpenFinishPodcast] = useState(false)
    const handleOpenFinishPodCast = () => {
        setOpenFinishPodcast(true)
    }
    const handleCloseFinishPodCast = () => {
        setOpenFinishPodcast(false)
    }

    //handleProceedToNext and save as draft
    const onSubmit = () => {
        if (values.title && values.sub_title && values.about && podcatsCategories.length !== 0) {
            if (podcastCoverFile) {

                let data = new FormData();
                data.append('title', values?.title);
                data.append('sub_title', values?.sub_title);
                data.append('about', values?.about);
                data.append('language_id', bookLanguage);
                data.append('category_ids', podcatsCategories);
                bookPrice && data.append('price', parseFloat(bookPrice).toFixed(2));
                data.append('status', 'Draft');
                podcastCoverFile && data.append('media', podcastCoverFile);
                podcastBannerFile && data.append('banner', podcastBannerFile);
                data.append('publish_to_feed', publishToFeed)

                setIsSavingPodcast(true)

                BaseServices.post(`${global_variables.podcats_url}/create/`, data)
                    .then((response) => {
                        console.log("create_pordcast_success:", response)
                        setTempPodcastData(response?.data)
                        message.success("New podcast was saved as draft!")
                        setIsSavingPodcast(false)
                        handleOpenFinishPodCast()
                    })
                    .catch((error) => {
                        setIsSavingPodcast(false)
                        console.log("create_podcast_error:", error)
                        message.error(error?.details || error?.response?.data?.detail || "An error occured, please try again")
                    });
            }
        }

    }

    //input validation
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
            title: "",
            sub_title: "",
            about: "",
            status: "",
        },
        validationSchema: CreatePodcastSchema,
        onSubmit,
    });


    //handleBookCoverSelect
    const handleBookCoverSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPodcastCoverFile(file);
            const url = URL.createObjectURL(file);
            setPodcastCoverUrl(url);
        }
    };

    //handleBookBannerSelect
    const handleBookBannerSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPodcastBannerFile(file);
            const url = URL.createObjectURL(file);
            setPodcastBannerUrl(url);
        }
    };

    return (
        <>
            <Modal width={600} open={isOpened} onCancel={handleClose} footer={null} maskClosable={false} keyboard={false}>
                <div className='text-black text-opacity-[80%] my-5 px-5'>
                    <p className='text-[26px] font-[700]'>Create a podcast</p>

                    <form onSubmit={handleSubmit}>
                        <div className="mt-[37px]">
                            <label htmlFor='title' className='text-[16px] font-[400]'>Title {errors.title && touched.title && <small className='text-xs text-red-500'>required</small>}</label>
                            <Input id="title" className={`${errors.title && touched.title && 'border-red-500'} w-full h-[52px] rounded-[20px] mt-[7px]`} onBlur={handleBlur} value={values.title} onChange={handleChange} />
                        </div>

                        <div className="mt-5">
                            <label htmlFor='sub_title' className='text-[16px] font-[400]'>Sub Title {errors.sub_title && touched.sub_title && <small className='text-xs text-red-500'>required</small>}</label>
                            <Input id="sub_title" className={`${errors.sub_title && touched.sub_title && 'border-red-500'} w-full h-[52px] rounded-[20px] mt-[7px]`} value={values.sub_title} onChange={handleChange} />
                        </div>

                        <div className="mt-5">
                            <label htmlFor='about' className='text-[16px] font-[400]'>About {errors.about && touched.about && <small className='text-xs text-red-500'>required</small>}</label>
                            <Input.TextArea id="about" className={`${errors.about && touched.about && 'border-red-500'} w-full h-[52px] rounded-[20px] mt-[7px]`} style={{ height: 100 }} onBlur={handleBlur} value={values.about} onChange={handleChange} maxLength={250} />
                        </div>


                        <div className="mt-5">
                            <div className='flex justify-between items-center'>
                                <label htmlFor='category' className='text-[16px] font-[400]'>Category {podcatsCategories.length === 0 && <small className='text-xs text-red-500'>required</small>}</label>
                                <Tooltip title='You can select multiple categories where applicable.' placement='top'>
                                    <BiInfoCircle className='h-5 w-5 cursor-pointer' />
                                </Tooltip>
                            </div>
                            <div className={`${podcatsCategories.length === 0 && 'border-red-500 border-opacity-[100%]'} w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]`}>
                                <BookCategorySelector onChange={(selected) => setPodCastCategories(selected)} />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label htmlFor='language' className='text-[16px] font-[400]'>Language</label>
                            <div className='w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]'>
                                <LanguageSelector onChange={(selected: any) => setBookLanguage(selected)} />
                            </div>
                        </div>

                        <div className="mt-5">
                            <label htmlFor='price' className='text-[16px] font-[400]'>Price</label>
                            <Input id="price" className={`w-full h-[52px] rounded-[20px] mt-[7px]`} value={bookPrice} onChange={(e) => setBookPrice(e.target.value)} />
                        </div>


                        {/* cover image */}
                        <div className="mt-5">
                            <div className='flex justify-between items-center'>
                                <small className='text-[16px] font-[400]'>Upload Podcast Cover
                                    {
                                        !podcastCoverFile && <small className='text-xs text-red-500'> required</small>
                                    }
                                </small>
                                <Tooltip title='Required dimension 226px by 226px' placement='top'>
                                    <BiInfoCircle className='h-5 w-5 cursor-pointer' />
                                </Tooltip>
                            </div>
                            <CoverImageUploader onChange={handleBookCoverSelect} placeholder='Select file to upload' />
                            <div className='flex justify-between mt-2'>
                                {podcastCoverFile && <p>Selected File: {podcastCoverFile.name}</p>}
                                {podcastCoverUrl && <img className='h-[100px] w-[100px] object-contain border border-gray-500 rounded' src={podcastCoverUrl} alt="Uploaded Cover" />}
                            </div>
                        </div>

                        {/* banner image */}
                        <div className="mt-5">
                            <div className='flex justify-between items-center'>
                                <small className='text-[16px] font-[400]'>Upload Podcast Banner</small>
                                <Tooltip title='Required dimension 844px by 311px' placement='top'>
                                    <BiInfoCircle className='h-5 w-5 cursor-pointer' />
                                </Tooltip>
                            </div>
                            <BannerImageUploader onChange={handleBookBannerSelect} placeholder='Select file to upload' />
                            <div className='flex justify-between mt-2'>
                                {podcastBannerFile && <p>Selected File: {podcastBannerFile.name}</p>}
                                {podcastBannerUrl && <img className='h-[100px] w-[100px] object-contain border border-gray-500 rounded' src={podcastBannerUrl} alt="Uploaded Banner" />}
                            </div>
                        </div>

                        {/* next button */}
                        <div className="mt-[30px] flex gap-2">
                            <p>Publish this podcast to the main feed</p>
                            <Switch defaultChecked={publishToFeed} onChange={(checked) => setPublishToFeed(checked)} />
                        </div>

                        <div className="mt-[20px]">
                            <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center' type='submit' onClick={onSubmit} disabled={isSavingPodcast}>
                                {
                                    isSavingPodcast ?
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                        :
                                        <p className='text-[16px] font-[700]'>Save</p>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>


            {/* finish publish podcast modal */}
            {
                openFinishPodcast && <FinishPublishingPodcast isOpened={openFinishPodcast} handleClose={handleCloseFinishPodCast} tempPodcastData={tempPodcastData} handleClosePrev={handleClose} />
            }
        </>
    )
}

export default PublishPodcastModal
